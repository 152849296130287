import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: ActivatedRoute, private route: Router) { }

  canActivate(): boolean{
   
    if(sessionStorage.getItem('accesstoken')){
     
     // this.route.navigate(['/home']);
      return true;
    } 
    this.route.navigate(['/error'], { queryParams: { page: 'unAuthorized' } });
    return false;
  }
}
