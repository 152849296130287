<div class="d-flex flex-column sticky-footer-wrapper min-vh-100">
    <app-header ></app-header>
    <p></p>
    <div class="container fill-height">
        <div class="row">
         <div class="col-lg-1"></div>
           <div id="hello" class="col-lg-10">
            <h4 style="text-align:center;"> {{ errorMessage }}</h4>
           </div>
         <div class="col-lg-1"></div>
       </div>
     </div>
     <app-footer></app-footer>
</div>


