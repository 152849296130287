import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import{ Globalconstants,enumAdverseEventValue } from 'src/app/helper/globalconstants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
page : string;
errorMessage: string;
  constructor(private route: ActivatedRoute, private router: Router) { 

    this.page = '';
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.page =  params['page'] ;
    });
  
    if(this.page === 'unAuthorized'){
        this.errorMessage = Globalconstants.UnAuthorizedMessage;
    } else if(this.page === 'sessiontimeout'){
        this.errorMessage = Globalconstants.SessionTimeOutMessage;
    }
   
  }

  ngOnInit(): void {
  }

}
