import { Component,OnInit } from '@angular/core';
import{ Globalconstants, enumcaseStatus,enumTabs } from 'src/app/helper/globalconstants';


@Component({
    selector :'app-header',
    templateUrl:'./header.component.html',
    
})
export class HeaderComponent implements OnInit{
  public sessionStorage = sessionStorage;
   // public loggedInUser: string;
    constructor() { 
      
    }
    
    ngOnInit(): void {
      
     // this.loggedInUser = sessionStorage.getItem('loggedInUser');
      //console.log(this.loggedInUser);
    }
    LogoutAndCloseTab(){
      var x=confirm(Globalconstants.LogOutMessage);
     // alert(x);
      if(x) 
      {
        sessionStorage.clear(); 
        window.top.close();
      }
    }
}