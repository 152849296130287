import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { MacraComponent } from './macra/macra.component';
import { CaretoolComponent } from './caretool/caretool.component';
import { FooterComponent } from './footer/footer.component';
import { TabsComponent } from './tabs/tabs.component';
import { CaseinfoComponent } from './caseinfo/caseinfo.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NumberDirectiveDirective } from './helper/number-directive.directive';
import { ErrorComponent } from './error/error.component';
import { AuthenticationComponent } from './authentication/authentication.component';  
import { AuthGuardService} from './_services/auth-guard/auth-guard.service';
import { JwttokenInterceptor } from './_services/interceptors/jwttoken.interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PhoneDirective } from './helper/phone.directive';
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { WebcamModule} from 'ngx-webcam';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MacraComponent,
    CaretoolComponent,
    FooterComponent,
    TabsComponent,
    CaseinfoComponent,
    HomeComponent,
    NumberDirectiveDirective,
    ErrorComponent,
    AuthenticationComponent,
    PhoneDirective,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    NgProgressModule.withConfig({
      spinnerPosition: "right",
      color: "#5D87A1",
      thick: true,
      meteor: true
    }),
    NgProgressHttpModule,
    WebcamModule
  ],
  providers: [
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass : JwttokenInterceptor,
      multi: true      
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
