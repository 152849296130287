import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CaseDetails } from 'src/app/_models/case-details';
import { Observable } from 'rxjs/internal/Observable';
import { PostCaseInfoResponse} from 'src/app/_models/post-case-info-response';
import { PostCqfCaseInfoResponse} from 'src/app/_models/post-cqf-case-info-response';

import {ProviderInfoResponse} from 'src/app/_models/provider-info-response';
import { RequestLookupAttributes } from 'src/app/_models/request-lookup-attributes';
import { environment } from 'src/environments/environment';
import { throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { document } from 'src/app/_models/case-details';
import { CaseRecResponse } from 'src/app/_models/case-rec-response';
import { CaseRecStatus } from 'src/app/_models/case-rec-status';
import{ Globalconstants } from 'src/app/helper/globalconstants';
import { Logdetails } from 'src/app/_models/logdetails';


@Injectable({
  providedIn: 'root'
})
export class MulesoftAPIServiceService {
  private headers: HttpHeaders;
  private logHeaders: HttpHeaders;
   
  constructor(private httpClient: HttpClient) { 
     this.headers = new HttpHeaders({ 'Access-Control-Allow-Headers' : 'Content-Type, Authorization'});
     //'Client-id': environment.clientIDValue , 'client-secret': environment.clientSecretValue });   
     this.logHeaders = new HttpHeaders({'client-id': environment.clientIDValue , 'client-secret': environment.clientSecretValue }); 
  }
  getCaseDetailsByID(CaseRecId: string): Observable<CaseDetails> {    
    return this.httpClient.get<CaseDetails>(environment.caseDetailsByIDAPIUrl + CaseRecId,  { headers: this.headers });
  }
  
  getCaseDetailsByIDInfo(CaseRecId: string): Observable<any> {    
    return this.httpClient.get(environment.caseDetailsByIDInfoAPIUrl + CaseRecId,  { headers: this.headers });
  }

  saveCaseDetailsCaseRecCQF(CaseDtl: CaseDetails,CaseRecId: string): Observable<PostCqfCaseInfoResponse> {    
    return this.httpClient.post<PostCqfCaseInfoResponse>(environment.saveCaseDetailsAPIUrl + CaseRecId +'/CQFCaseData', CaseDtl,{ headers: this.headers });    
  }

  saveCaseDetails(CaseDtl: CaseDetails,CaseRecId: string): Observable<PostCaseInfoResponse> {    
    return this.httpClient.post<PostCaseInfoResponse>(environment.saveCaseDetailsAPIUrl + CaseRecId , CaseDtl,{ headers: this.headers });
  }

  getProviderInfo(): Observable<ProviderInfoResponse[]> {
    return this.httpClient.get<ProviderInfoResponse[]>(environment.providerInfoAPIUrl, { headers: this.headers });
  }
  

  getFormValues(): Observable<RequestLookupAttributes> {
    return this.httpClient.get<RequestLookupAttributes>(environment.formValuesAPIUrl, { headers: this.headers });
  } 
  
  uploadFiles(formData: FormData, CaseRecId: string, appName: string): Observable<document> {
    return this.httpClient.post<document>(environment.fileuploadUrl +  CaseRecId + '/apps/' + appName + '/files', formData, { 
        headers: this.headers
        
        //,  reportProgress: true 
      }).pipe(
                catchError(err => this.handleError(err))
            );
    }
    private handleError(error: any) {
        return throwError(error);
    }

  getFileByName(CaseRecId: string, appName: string, fileName: string):Observable<any>{
   
    //return this.httpClient.get(environment.fileuploadUrl +  CaseRecId + '/apps/' + appName + '/files?fileName=' + fileName + '&expirationTime=60000',{
      return this.httpClient.get(environment.fileuploadUrl +  CaseRecId + '/apps/' + appName + '/fileurl?fileName=' + fileName + '&expirationTime=' + Globalconstants.FileExpirationTime     
        ,{
      headers: this.headers//,
     // responseType: 'arrayBuffer' as 'json' 
      // observe: 'response'
    }).pipe(
            catchError(err => this.handleError(err))
        );
  }

  deleteFile(CaseRecId: string, appName: string, fileName: string):Observable<any>{
    return this.httpClient.delete(environment.fileuploadUrl +  CaseRecId + '/apps/' + appName + '/files?fileName=' + fileName,{
      headers: this.headers
      // observe: 'response'
    }).pipe(
            catchError(err => this.handleError(err))
        );

  }

  getCaserecPatientDemographicDetails(CaseRecId: string,RequestorId: string):Observable<CaseRecResponse>{
    return this.httpClient.get<CaseRecResponse>(environment.caserecPatientDemographicAPIUrl + CaseRecId + '/' + RequestorId,  { headers: this.headers })
    .pipe(
        catchError(err => this.handleError(err))
    );
  }
  UpdateCaseRecStatus(CaseStatus: CaseRecStatus,CaseRecId: string): Observable<PostCaseInfoResponse> {
    return this.httpClient.put<PostCaseInfoResponse>(environment.caserecPatientDemographicAPIUrl + CaseRecId + '/updatestatus' , CaseStatus,{ headers: this.headers });
  }

  SaveLogDetails(logMessageDetails :Logdetails){
    return this.httpClient.post<string>(environment.logServiceUrl, logMessageDetails, { headers: this.logHeaders} );
 }
}
