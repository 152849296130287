import { Component, TemplateRef, ViewChildren, ElementRef, ViewChild, Attribute, Inject ,HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Observable, Observer, Subject, fromEvent} from 'rxjs';
import {debounceTime, map, take} from 'rxjs/operators';
import {  FormGroup, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MulesoftAPIServiceService } from 'src/app/_services/mulesoft/mulesoft-apiservice.service';
import { ProviderInfoResponse } from 'src/app/_models/provider-info-response';
import { GroupAttribute } from 'src/app/_models/request-lookup-attributes';
import { provider, attribute } from '../_models/case-details';
import { document } from 'src/app/_models/case-details';
import { TabsComponent } from 'src/app/tabs/tabs.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import{ Globalconstants,enumAdverseEventValue, enumASALevel, ASALevelByenumKey, enumcaseStatus } from 'src/app/helper/globalconstants';
import { LogService } from 'src/app/_services/log/log.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { formatDate } from '@angular/common';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
  selector: 'app-caretool',
  templateUrl: './caretool.component.html',
  styleUrls: ['./caretool.component.css']
})
export class CaretoolComponent   {
  public IsMIPSAPINA: boolean = true;
  public IsMIPSCOINA: boolean = true;
  public IsMIPSPMHNA: boolean = true;
  
  
  public ChkMultiIndicators:boolean = false;
  public CaseRecID:Number;
  public RiskdrpASA:boolean = false;
  RiskdrpTimeElapsed:boolean = false;
  RiskdrpClinicalOutcome:boolean = false;
  RiskdrpSOR:boolean = false;
  RiskCT607:boolean = false;
  RiskStatusError:boolean = false;
  isValidDOS:boolean = true;
  isEmailValidate:boolean = true;
  isIndigridDisplayError:boolean = false;
  isIndigridDisplay:boolean = false;
  isgridDisplayErrorPMH:boolean = false;
  isgridDisplayPMH:boolean = false;
  isIncompleteCase :boolean = true;

  parentIndicators: string;
  childIndicators: string;
  public ErrorMsgIndicators: string;
  public ErrorMsgPastMedicalHistory: string;
  public arr: attribute[];
  public arrReturn: attribute[];
  public arrCancelData: attribute[];
  public arrClinicalIndicators: attribute[];
  public arrPMH: attribute[];
  public arrCancelDataPMH: attribute[];
  public arrPastMedicalHistory: attribute[];
  BMI: number;
  HGT: number;
  WGT: number;
  SameAsDOS: string;
  public AnesTypeArrList: GroupAttribute[];
  public ClinicalIndicators: GroupAttribute[];
  // public PastMedicalHistory: GroupAttribute[];
  // public PatinetTypeArrList: GroupAttribute[];
  // public TimeElapsedArrList: GroupAttribute[];
  // public ClinicalOutcmArrList: GroupAttribute[];
  public ASAClasificationArrList: GroupAttribute[];
  isAddClinicianFormSubmitted:boolean  = false;  
  // public SourceofReportArrList: GroupAttribute[];  
  // public HeightUnitArrList :  GroupAttribute[];
  // public WeightUnitArrList : GroupAttribute[];
  public isErrorAlert: boolean=false;
  public ErrorMsg: string;
  public isErrorAlertAPICall: boolean=false;
  public ErrorMessageAPICall:string ="";
  
  hasErrorClinicalIndicators:boolean = false;
  hasErrorPastMedicalHistory:boolean = false;
  hasErrorsManualLastname:boolean = false;
  hasErrorsClinician:boolean = false;
  isFormSubmitted:boolean  = false;
  public providers: ProviderInfoResponse[];
  @ViewChildren('myItem') item;
  selectedIndicatorsIds = [];
  selectedIndicatorsIds1 = [];
  selectedIndicatorsIds2 = [];
  selectedPMHIds = [];
  selectedPMHIds1 = [];
  selectedPMHIds2 = [];
  display:any;
  data:any;
  dec:any;
  caretoolform: FormGroup; 
  addClinicianform: FormGroup; 
  FormVal : any; 
  public newClinicians:  ProviderInfoResponse[];
  public newCliniciansCaseRec:  ProviderInfoResponse[];
  public provider: provider[];
  isManualLastNameDisplay: boolean = false;
  isLastNameDisplay: boolean = true;
  
  isDisplay:boolean = false;
  modalRef: BsModalRef;
  //result:any;
  public asaValMacra: string; 

  selectedFiles: FileList;
  message = '';
  public uploadedFileInfo: document[];
  DateOfService: any;
  inValidFileTypes = Globalconstants.inValidFileTypes;
  appName: string = Globalconstants.appName;  
  isDocgridDisplay: boolean = false;
  isFileSelected: boolean = false;
  messagecolor: string;
  hasAdverseEventErrors: boolean = false;
  datePickerConfig : Partial<BsDatepickerConfig>;
  @ViewChild('inputFile') myInputVariable: ElementRef;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  /** Start Web cam variables */
  public showWebcam = false;
  public HideCamera = true;
  //public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public isMobile= false;
  public errors: WebcamInitError[] = [];
  public camImage = false;
  private width: number;
  private height: number;
  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  base64TrimmedURL: string;
  base64DefaultURL: string;
  generatedImage: string;
  mobileMessage:string ='';
  mobileMessagecolor:string ='';
  public cameraPermission:string = '';
  /** End Web cam variables */

  public objProvider: ProviderInfoResponse;
  
  constructor(private fb: FormBuilder,private modalService: BsModalService, private apiService: MulesoftAPIServiceService, 
    private el: ElementRef, @Inject(TabsComponent) private parent: TabsComponent,private logger: LogService , 
    private SpinnerService: NgxSpinnerService) {

      
      /** Start Web cam declarations */
    this.onResize();
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
    this.isMobile = this.isMobileDevice();
     /** End Web cam declarations */
        this.arr= []; 
        this.arrPMH= []; 
        this.uploadedFileInfo=[];
        this.datePickerConfig = Object.assign({}, 
          {
            containerClass : 'theme-dark-blue',
            showWeekNumbers: false
          
          });
        this.arrCancelData = [];
        this.arrCancelDataPMH = [];
    
       this.caretoolform = this.fb.group({
        CT000: [''],
        drpPatientType: [''],
        drpHeightUnit: [''],
        drpWeightUnit: [''],
        drpAnesthesiaType: ['',Validators.required],
        drpTimeElapsed: ['',Validators.required],
        drpClinicalOutcome: ['',Validators.required],
        drpASA: ['',Validators.required],
        drpSOR: ['',Validators.required],        
        CT607: ['',Validators.required],        
        file: [''],
        CT605: ['',Validators.required],
        CT608: [''],
        CT548: [''],
        CT549: [''],
        CT550: [''],//new FormControl({value:'', disabled:true}),
        CT533: [''],
        SameAsDOS:[''],
        CT508: [''],
      })
      
    
      

      
   
  //Mulesoft API call to get all define values from Database.
  
     
  }
  
  public model: ProviderInfoResponse;

  BindDatafromDB(objProvider: ProviderInfoResponse, patientDOS: Date)
  {
    
  this.newClinicians = []; 
  this.newCliniciansCaseRec = [];   
  this.CaseRecID = +sessionStorage.getItem('caserecid');
  if(this.CaseRecID > 0 && this.CaseRecID!= undefined)
  {
  // this.apiService.getCaserecPatientDemographicDetails(this.CaseRecID.toString()).subscribe(
  //   result => { 
  //               //get all caserec provider information
  //               const objProvider: ProviderInfoResponse = {
  //               providerFirstName:  result.providerFirstName,
  //               providerLastName:   result.providerLastName,
  //               providerMiddleName: '',
  //               providerId:         '',
  //               providerNPI:         result.providerNPI,
  //               email:             result.providerEmail,
  //               phone:              result.providerPhone,
  //               serviceLine:        result.caseType,
  //               providerTitle  :'' ,
  //               isManualEntry :'0',
  //               caserecProvider:JSON.parse("true")
  //           }

            this.SameAsDOS= formatDate(patientDOS, 'MM/dd/yyyy', 'en_US');
            if(this.SameAsDOS)
            {
              this.caretoolform.get('CT605').setValue(this.SameAsDOS);      
              this.caretoolform.get('SameAsDOS').setValue('1');
              //console.log('default');
            }
            
            if(this.newClinicians.length < 1)
            {              
              this.newClinicians.push(objProvider);
            }

            if(this.newCliniciansCaseRec.length < 1)
            {
              this.newCliniciansCaseRec.push(objProvider);
            }  
              
        //  });
        }
          //End
  
      //Mulesoft API call to get existing CaseRec details.
      if(sessionStorage.getItem('caserecid'))
      {
      // this.apiService.getCaseDetailsByID(sessionStorage.getItem('caserecid')).subscribe(
      //   result => {   
              
      if(sessionStorage.getItem("caseDetailsByIDObject"))
      {
              var result =  JSON.parse(sessionStorage.getItem("caseDetailsByIDObject"));
              //console.log('result'+result);
              
              if(result.attributes)
              {
                  for (let i = 0; i < result.attributes.length; i++) {
                        //console.log("in getcasebyID:", result);
                        //console.log("in getcasebyID-2:", this.newClinicians);
                        if (result.providers == null)
                        {
                          if(this.newClinicians.length < 1)
                          {
                            this.newClinicians.push(objProvider);
                          }
                        }
                        else{
                          this.newClinicians = result.providers;
                        }

                      //  this.newClinicians = result.providers;
                      //  if(this.newClinicians.length < 1)
                      //  {              
                      //      this.newClinicians.push(objProvider);
                      //  }
                      
                        if(result.documents.length >= 1){
                            this.isDocgridDisplay = true;
                            this.uploadedFileInfo = result.documents;
                        }
                        
                        if(sessionStorage.getItem('AnesTypeArrList')){
                          var valAnes =  JSON.parse(sessionStorage.getItem('AnesTypeArrList'));                        
                          let data = valAnes.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                          if(data)
                          {                          
                            this.caretoolform.get('drpAnesthesiaType').setValue(result.attributes[i].attributeId);
                            //this.IsMIPSCOINA = false;
                          } 
                        }                        
                                               
                        // if(sessionStorage.getItem('PatinetTypeArrList')){
                        //     var pType =  JSON.parse(sessionStorage.getItem('PatinetTypeArrList'));                        
                        //     let ptdata = pType.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                        //     if(ptdata)
                        //     {                          
                        //       this.caretoolform.get('drpPatientType').setValue(result.attributes[i].attributeId);
                        //       this.IsMIPSAPINA = false;
                        //     }
                        // }
                       
                        // if(sessionStorage.getItem('TimeElapsedArrList')){                           
                        //     var tElapsed =  JSON.parse(sessionStorage.getItem('TimeElapsedArrList'));                        
                        //     let TmEdata = tElapsed.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                        //     if(TmEdata)
                        //     {                          
                        //       this.caretoolform.get('drpTimeElapsed').setValue(result.attributes[i].attributeId);
                        //       this.IsMIPSCOINA = false;
                        //     }
                        // }
                        
                        // if(sessionStorage.getItem('ClinicalOutcmArrList')){
                             
                        //     var clinicaloc =  JSON.parse(sessionStorage.getItem('ClinicalOutcmArrList'));                        
                        //     let Clidata = clinicaloc.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                        //     if(Clidata)
                        //     {                          
                        //       this.caretoolform.get('drpClinicalOutcome').setValue(result.attributes[i].attributeId);
                        //       this.IsMIPSCOINA = false;
                        //     }
                        // }
                       
                        if(sessionStorage.getItem('ASAClasificationArrList')){
                             
                            var ASACla =  JSON.parse(sessionStorage.getItem('ASAClasificationArrList'));                        
                            let ASAdata = ASACla.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                            if(ASAdata)
                            {                          
                              this.caretoolform.get('drpASA').setValue(result.attributes[i].attributeId);
                            }
                        }
                       
                        if(sessionStorage.getItem('SourceofReportArrList')){
                               
                              var SORPT =  JSON.parse(sessionStorage.getItem('SourceofReportArrList'));                        
                              let SORdata = SORPT.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                              if(SORdata)
                              {                          
                                this.caretoolform.get('drpSOR').setValue(result.attributes[i].attributeId);
                                this.IsMIPSCOINA = false;
                              }
                        }
                       
                        if(sessionStorage.getItem('HeightUnitArrList')){
                            
                            var hgt =  JSON.parse(sessionStorage.getItem('HeightUnitArrList'));                        
                            let Hdata = hgt.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                            if(Hdata)
                            {                          
                              this.caretoolform.get('drpHeightUnit').setValue(result.attributes[i].attributeId);
                              this.IsMIPSAPINA = false;
                            }
                        }
                       
                        if(sessionStorage.getItem('WeightUnitArrList')){
                            
                            var wgt =  JSON.parse(sessionStorage.getItem('WeightUnitArrList'));                        
                            let Wdata = wgt.find(ob => ob['groupAttributeId'] === result.attributes[i].attributeId);                        
                            if(Wdata)
                            {                          
                              this.caretoolform.get('drpWeightUnit').setValue(result.attributes[i].attributeId);
                              this.IsMIPSAPINA = false;
                            }
                        }
                        
                     if(result.attributes[i].attributeId =="CT000")
                      {   
                                   
                          this.caretoolform.get('CT000').setValue(result.attributes[i].attributeValue);                   
                          if (result.attributes[i].attributeValue=="1")
                          {
                            this.isDisplay = true;
                            this.parent.AdverseEventValue = enumAdverseEventValue.Yes;
                          }
                          if(result.attributes[i].attributeValue=="0"){
                            this.parent.AdverseEventValue = enumAdverseEventValue.No;
                          }
                      }
                      
                      else if(result.attributes[i].attributeId =="CT533")
                      {                   
                          this.caretoolform.get('CT533').setValue(result.attributes[i].attributeValue);                   
                      }
                      else if(result.attributes[i].attributeId =="CT607")
                      {                   
                          this.caretoolform.get('CT607').setValue(result.attributes[i].attributeAdditionalNotes);                   
                      }
                      else if(result.attributes[i].attributeId =="CT508")
                      {                   
                          this.caretoolform.get('CT508').setValue(result.attributes[i].attributeAdditionalNotes);                   
                          this.IsMIPSCOINA = false;
                      }
                      else if(result.attributes[i].attributeId =="CT605")
                      { 
                        
                          
                          this.caretoolform.get('CT605').setValue(formatDate(result.attributes[i].attributeValue, 'MM/dd/yyyy', 'en_US'));                   
                          
                          
                          if(this.caretoolform.get('CT605').value == this.SameAsDOS)
                          {
                            this.caretoolform.get('SameAsDOS').setValue('1');
                            //console.log(this.caretoolform.get('SameAsDOS').value);
                          }
                          else{
                            //this.caretoolform.get('SameAsDOS').setValue('0');
                            this.caretoolform.get('SameAsDOS').reset();                           
                          }
                         
                          
                      }

                      else if(result.attributes[i].attributeId =="CT608")
                      {                   
                          this.caretoolform.get('CT608').setValue(result.attributes[i].attributeValue);  
                          if(result.attributes[i].attributeValue==="1")
                          {
                              this.RiskStatusError= true;
                          }                                           
                      }
                      else if(result.attributes[i].attributeId =="CT548")
                      {                   
                          this.caretoolform.get('CT548').setValue(result.attributes[i].attributeValue); 
                          this.IsMIPSAPINA = false;                  
                      }
                      else if(result.attributes[i].attributeId =="CT549")
                      {                   
                          this.caretoolform.get('CT549').setValue(result.attributes[i].attributeValue);                   
                          this.IsMIPSAPINA = false;
                      }
                      else if(result.attributes[i].attributeId =="CT550")
                      {                   
                          this.caretoolform.get('CT550').setValue(result.attributes[i].attributeValue);   
                          this.IsMIPSAPINA = false;                
                      }                     
 
                  }
            }

            if(result.attributes)
            {
              this.arr = result.attributes;
              this.getData(); 

              // this.arrPMH = result.attributes;
              // this.getDataPMH();                    

            }
                 
      //             },
                 
      //            error => {console.error(error); this.isErrorAlertAPICall = true; this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
      

      // );
          }
          if(result.status == enumcaseStatus.Complete){
            this.parent.isDisabled = true;
            this.parent.isInfoAlert = true;
            this.parent.InfoMessage = Globalconstants.InfoMessage;  
            //this.parent.btnClose = true;                   
            
            //this.parent.DisableAllTabs();
            this.parent.isTabDisplay = false;
            this.caretoolform.disable();  
            this.isIncompleteCase = false;          
            }
            
    }

  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term.length < 2 ? []
        : this.providers.filter(v => (v.providerLastName).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  
   formatter = (x: {providerLastName: string, providerFirstName: string}) => (x.providerLastName + ' ' + x.providerFirstName);

   inputFormatter = (x: {providerLastName: string}) => (x.providerLastName);  
   

    _isContains(json, value) {
    let contains = false;
    Object.keys(json).some(key => {
        contains = typeof json[key] === 'object' ? this._isContains(json[key], value) : json[key] === value;
         return contains;
    });
    return contains;
 }

   
  selectedItem(item){
    
    this.addClinicianform.get('lastName').reset();
    this.addClinicianform.get('lastName').setValue(item.item.providerLastName);
    this.addClinicianform.get('hdnlastName').setValue(item.item.providerLastName);    
    this.addClinicianform.get('firstName').setValue(item.item.providerFirstName);
    this.addClinicianform.get('providerNPI').setValue(item.item.providerNPI);
    this.addClinicianform.get('email').setValue(item.item.email);
    this.addClinicianform.get('phone').setValue(item.item.phone);
    this.addClinicianform.get('txtProviderTitle').setValue(item.item.providerTitle);
    
    
  }
  onManualProvider(event){

    try  
          {     
            this.isErrorAlert= false;
            this.ErrorMsg = ""; 
            this.hasErrorsManualLastname = false;            
   
    if(this.addClinicianform.get('ManualProvider').value ===true){
       this.isManualLastNameDisplay = true;
       this.isLastNameDisplay = false;
        
        this.addClinicianform.get('txtProviderTitle').enable();
        this.addClinicianform.get('email').enable();
        this.addClinicianform.get('firstName').enable();
        this.addClinicianform.get('providerNPI').enable();
        this.addClinicianform.get('phone').enable();
        this.addClinicianform.get('txtProviderTitle').reset();
        this.addClinicianform.get('email').reset();
        this.addClinicianform.get('firstName').reset();
        this.addClinicianform.get('providerNPI').reset();
        this.addClinicianform.get('phone').reset();         
        this.addClinicianform.controls['lastName'].clearValidators();      
        this.addClinicianform.controls["ManualLastName"].setValidators([Validators.required, Validators.pattern(/.*\S.*/)]);
    }
    else{
      this.isLastNameDisplay = true;
      this.isManualLastNameDisplay = false;
      
      this.addClinicianform.get('txtProviderTitle').disable();
        this.addClinicianform.get('email').disable();
        this.addClinicianform.get('firstName').disable();
        this.addClinicianform.get('providerNPI').disable();
        this.addClinicianform.get('phone').disable();     
        this.addClinicianform.controls['ManualLastName'].clearValidators();
        this.addClinicianform.controls['ManualLastName'].setErrors(null);        
        
    }
  }  
  catch(error)  
  { 
    var arrayParams = [sessionStorage.getItem('caserecid')]
    this.logger.log("onManualProvider()","Error raised in caretool.component.ts - onManualProvider() ", 
    arrayParams,  error.message);                            
    this.isErrorAlert = true;
    this.ErrorMsg = Globalconstants.ErrorMessageUI; 
  } 
  }
  toggleCARETOOLForm(event){   
    sessionStorage.setItem('IsChanged','Yes');
    this.hasAdverseEventErrors = false;
    if(event.target.value ===enumAdverseEventValue.Yes){
      this.isDisplay = true;
      this.parent.AdverseEventValue = enumAdverseEventValue.Yes;
    }
    else {
      this.isDisplay = false;
      this.parent.AdverseEventValue = enumAdverseEventValue.No;
    }
  }
  
  openModal(template: TemplateRef<any>) {
    try  
          {    
            //load providers from database through API 
            this.providers = [];
            //Get Provider Info from API
            this.apiService.getProviderInfo().subscribe(
              (result: ProviderInfoResponse[]) =>{
                  this.providers = result['providers'];                
              });
            //End API call 
            this.isErrorAlert= false;
            this.ErrorMsg = ""; 
            this.addClinicianform.reset();    
            this.isEmailValidate = true;
            
            this.isLastNameDisplay = true;
            this.isManualLastNameDisplay = false;
            this.hasErrorsManualLastname = false;
            this.addClinicianform.get('txtProviderTitle').disable();
            this.addClinicianform.get('email').disable();
            this.addClinicianform.get('firstName').disable();
            this.addClinicianform.get('providerNPI').disable();
            this.addClinicianform.get('phone').disable(); 
            this.addClinicianform.get('lastName').reset();
            this.isAddClinicianFormSubmitted = false;
          
            this.modalRef = this.modalService.show(template,  Object.assign({}, { class: 'gray modal-lg', ignoreBackdropClick: true, keyboard: false }) );
          }  
          catch(error)  
          { 
            var arrayParams = [sessionStorage.getItem('caserecid')]
            this.logger.log("openModal() - getProviderInfo()","Error raised in caretool.component.ts - openModal() ", arrayParams,  error.message);                      
            this.isErrorAlert = true;
            this.ErrorMsg = Globalconstants.ErrorMessageUI; 
          } 

  }
  openClinicalIndicatorModal(template: TemplateRef<any>) {
    try  
          {     
            this.isErrorAlert= false;
            this.ErrorMsg = ""; 
    this.isIndigridDisplayError = false;
    this.ErrorMsgIndicators = "";    
    for (let i = 0; i < this.arrCancelData.length; i++)
           {
               for (let k = 0; k < this.ClinicalIndicators.length; k++)
               {
                const index = this.arr.findIndex(x => x.attributeId === this.arrCancelData[i].attributeId);                                      
                  if (index !== -1) {
                    this.arr.splice(index, 1);
                  } 

                  if(this.arrCancelData[i].attributeId == this.ClinicalIndicators[k].groupAttributeId)
                  {
                    this.ClinicalIndicators[k].isChecked = false;
                  }
                  for(let j = 0; j < this.ClinicalIndicators[k].groupSubAttribute.length; j++)
                  {
                      if(this.arrCancelData[i].attributeId  == this.ClinicalIndicators[k].groupSubAttribute[j].groupAttributeSubItemId)
                      {
                        this.ClinicalIndicators[k].groupSubAttribute[j].isChecked = false;
                      }
                  }                 
                }
            }
           
    this.modalRef = this.modalService.show(template,  Object.assign({}, { class: 'gray modal-sg', ignoreBackdropClick: true, keyboard: false }) );
  }  
  catch(error)  
  { 
    var arrayParams = [sessionStorage.getItem('caserecid')]
          this.logger.log("openClinicalIndicatorModal()","Error raised in caretool.component.ts - openClinicalIndicatorModal() ", arrayParams,  error.message);                         
    this.isErrorAlert = true;
    this.ErrorMsg = Globalconstants.ErrorMessageUI; 
  } 

  }


  // openPMHModal(template: TemplateRef<any>) {
  //   try
  //   {
  //   this.isgridDisplayErrorPMH = false;
  //   this.ErrorMsgPastMedicalHistory = "";    
  //   for (let i = 0; i < this.arrCancelDataPMH.length; i++)
  //          {
  //              for (let k = 0; k < this.PastMedicalHistory.length; k++)
  //              {
  //               const index = this.arrPMH.findIndex(x => x.attributeId === this.arrCancelDataPMH[i].attributeId);                                      
  //                 if (index !== -1) {
  //                   this.arrPMH.splice(index, 1);
  //                 } 

  //                 if(this.arrCancelDataPMH[i].attributeId == this.PastMedicalHistory[k].groupAttributeId)
  //                 {
  //                   this.PastMedicalHistory[k].isChecked = false;
  //                 }
                                      
  //               }
  //           }
           
  //   this.modalRef = this.modalService.show(template,  Object.assign({}, { class: 'gray modal-lg', ignoreBackdropClick: true, keyboard: false }) );
  // }  
  // catch(error)  
  // { 
  //   var arrayParams = [sessionStorage.getItem('caserecid')]
  //     this.logger.log("openPMHModal()","Error raised in caretool.component.ts - openPMHModal() ", arrayParams,  error.message);                            
  //   this.isgridDisplayErrorPMH = true;
  //   this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMessageUI; 
  // } 
  // }

  ClinicianList(): ProviderInfoResponse[]
  { 
    if(this.caretoolform.get('CT000').value ==="0")
    {
      return this.newCliniciansCaseRec;
    }
    else
    {
    return this.newClinicians;
    }
    
  }

  DocumentList(): document[]{
    
    //console.log(this.uploadedFileInfo);
    if(this.caretoolform.get('CT000').value ==="0")
    {
      return null;
    }
    else
    {
     return this.uploadedFileInfo;
    }     
    }
  onSave(){   
    if(this.caretoolform.get('CT605').value)
    {
     
    var OldDt = new Date(Globalconstants.minDtforDOS);
    var d = new Date(this.caretoolform.get('CT605').value);
                  if(d.toString() === "Invalid Date")
                  {
                    this.isValidDOS = false;
                  }
      if(d.getTime() < OldDt.getTime())         
      {
        this.isValidDOS = false;
      }
              

    }
    if(this.parent.AdverseEventValue === enumAdverseEventValue.Empty){
      this.hasAdverseEventErrors = true;
     // this.scrollToFirstInvalidControl();
      return false;
    } 
    else if(!this.isValidDOS)
    {
      var d = new Date(this.caretoolform.get('CT605').value);
      if(d.toString() === "Invalid Date")
      {
        //this.scrollToFirstInvalidControl();
        //scroll to top of screen
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        return false;
      }

      var OldDt = new Date(Globalconstants.minDtforDOS);
      var d = new Date(this.caretoolform.get('CT605').value);                 
      if(d.getTime() < OldDt.getTime())         
      {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        return false;
      }
    }
      return true;
   
  }
 
  onSubmit() : boolean {
   this.isFormSubmitted = true; 
   if(this.caretoolform.get('CT605').value)
    {
    var d = new Date(this.caretoolform.get('CT605').value);
                  if(d.toString() === "Invalid Date")
                  {
                    this.isValidDOS = false;
                  }

      var OldDt = new Date(Globalconstants.minDtforDOS);
      var d = new Date(this.caretoolform.get('CT605').value);                 
      if(d.getTime() < OldDt.getTime())         
      {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        return false;
      }

    }
   
    if(this.parent.AdverseEventValue === enumAdverseEventValue.Empty){
        this.hasAdverseEventErrors = true;
        
        return false;
    } else if(this.parent.AdverseEventValue === enumAdverseEventValue.Yes){
     
          if (!this.caretoolform.valid) {
            
            if(this.caretoolform.get('CT000').value ==="0")
            {
                return true;
            }
            else{             

              //if(this.RiskStatusError==true && (this.caretoolform.controls["drpASA"].errors || this.caretoolform.controls["drpTimeElapsed"].errors || this.caretoolform.controls["drpClinicalOutcome"].errors || this.caretoolform.controls["drpSOR"].errors || this.caretoolform.controls["CT607"].errors || this.selectedPMHIds2.length<1))
              if(this.RiskStatusError==true && (this.caretoolform.controls["drpASA"].errors || this.caretoolform.controls["drpTimeElapsed"].errors || this.caretoolform.controls["drpClinicalOutcome"].errors || this.caretoolform.controls["drpSOR"].errors || this.caretoolform.controls["CT607"].errors))
              {
                console.log('this.RiskStatusError:' + this.RiskStatusError);
                document.getElementById("collapseCOI").classList.add('collapse');
                document.getElementById("collapseCOI").classList.add('show');
                if(this.selectedIndicatorsIds2.length < 1)
                {                
                  this.hasErrorClinicalIndicators = true; 
                }
                return false;
              }
              
              if(this.selectedIndicatorsIds2.length < 1)
              {                
                this.hasErrorClinicalIndicators = true;               
                return false;
              }
              else if(this.isValidDOS == false)
              {
               // this.scrollToFirstInvalidControl();
                var d = new Date(this.caretoolform.get('CT605').value);
                  if(d.toString() === "Invalid Date")
                  {
                            this.scrollToFirstInvalidControl();
                            return false;
                  }
                  else
                  {
                    var OldDt = new Date(Globalconstants.minDtforDOS);
                    var d = new Date(this.caretoolform.get('CT605').value);                 
                    if(d.getTime() < OldDt.getTime())         
                    {
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                      });
                      return false;
                    }
                    this.isValidDOS =true;
                  }
              }
              else{
                this.hasErrorClinicalIndicators = false;
                this.isValidDOS =  true;
              }

              if(this.caretoolform.controls["drpAnesthesiaType"].errors)
              {
                return false;
              }

              if(this.caretoolform.controls["CT607"].errors)
              {
                return false;
              }

              if(this.caretoolform.controls["drpASA"].errors)
              {
                return false;
              }
              
              return true;
            }
      }
      else{

        // if(this.RiskStatusError==true && (this.selectedPMHIds2.length<1))
        // {          
        //   if(this.selectedPMHIds2.length<1)
        //   {
        //     this.hasErrorPastMedicalHistory = true;
        //   }
        //   else{
        //     this.hasErrorPastMedicalHistory = false;
        //   }
        //   return false;
        // }
          
         if(this.selectedIndicatorsIds2.length < 1)
          {
            this.hasErrorClinicalIndicators = true;
            //this.scrollToFirstInvalidControl();
            return false;
          }
          else if(this.isValidDOS == false)
              {
                var d = new Date(this.caretoolform.get('CT605').value);
                  if(d.toString() === "Invalid Date")
                  {
                            this.scrollToFirstInvalidControl();
                            return false;
                  }
                  else
                  {this.isValidDOS =true;}
              }
          else{
            this.hasErrorClinicalIndicators = false;
            this.isValidDOS =  true;
          }
          return true;
      }
    } else {
      return true;
    }

  }

  IsCompletedCase() {
    //this.parent.isInfoAlert = true;
            //this.parent.InfoMessage = Globalconstants.InfoMessage;  
            //this.parent.btnClose = true;                   
            
            //this.parent.DisableAllTabs();
            this.parent.isTabDisplay = false;
            this.caretoolform.disable();  
            this.isIncompleteCase = false;
            this.isFileSelected =  false ;

  }
  


  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".text-danger"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(500),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
   // console.log("In Caretool");
    const labelOffset = 50;
    //return window.scrollY - labelOffset;
   return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  
  SaveModalValues(){

    try
    {
      sessionStorage.setItem('IsChanged','Yes');
      this.isErrorAlert= false;
      this.ErrorMsg = ""; 
      this.isAddClinicianFormSubmitted = true;
    
      if (this.addClinicianform.valid == true && this.isManualLastNameDisplay == false) {
       
       if(!this.addClinicianform.get('firstName').value)
       {         
         this.isErrorAlert = true; 
         this.ErrorMsg = Globalconstants.ErrorMsgClinician;        
         return false;
       }
       else
       {
        this.isErrorAlert = false;
       }
       

     
            this.BindModalValuesToGrid(<ProviderInfoResponse>{
              "providerFirstName": this.addClinicianform.get('firstName').value,
              "providerLastName" : this.addClinicianform.get('hdnlastName').value,
              "providerNPI" : this.addClinicianform.get('providerNPI').value,
              "email" : this.addClinicianform.get('email').value,
              "phone" : this.addClinicianform.get('phone').value ,
              "providerTitle" : this.addClinicianform.get('txtProviderTitle').value ,
              "isManualEntry": "0", 
              "caserecProvider": JSON.parse("false")         
          });
          
          if(this.isErrorAlert === false)
          {
            this.addClinicianform.reset();
            this.addClinicianform.controls['ManualLastName'].setErrors(null);
            this.modalRef.hide();
          }
          else if(this.isErrorAlert === true)
          {
            this.ErrorMsg = Globalconstants.ErrorMsgClinicianAdded;
          }
      }
      else if(this.isManualLastNameDisplay ==true)
      {
        if(this.addClinicianform.controls["firstName"].errors || this.addClinicianform.controls["txtProviderTitle"].errors )
        {        
          return false;
        }

        if(this.addClinicianform.controls["ManualLastName"].errors || this.addClinicianform.controls["ManualLastName"].errors?.pattern )
        {
          this.hasErrorsManualLastname = true;
          return false;
        }
        
        if(this.isEmailValidate === false)
        {                 
          return false;
        }
        

        this.BindModalValuesToGrid(<ProviderInfoResponse>{
          "providerFirstName": this.addClinicianform.get('firstName').value?.trim(),
          "providerLastName" : this.addClinicianform.get('ManualLastName').value?.trim(),
          "providerNPI" : this.addClinicianform.get('providerNPI').value,
          "email" : this.addClinicianform.get('email').value,
          "phone" : this.addClinicianform.get('phone').value,
          "providerTitle" : this.addClinicianform.get('txtProviderTitle').value?.trim(),
          "isManualEntry": "1",
          "caserecProvider": JSON.parse("false")            
      });

      // this.addClinicianform.reset();      
      // this.modalRef.hide();

      if(this.isErrorAlert === false)
      {
        this.addClinicianform.reset();
        this.modalRef.hide();
        this.addClinicianform.controls['ManualLastName'].setErrors(null);

        this.isLastNameDisplay = true;
        this.isManualLastNameDisplay = false;
        this.hasErrorsManualLastname = false;
        this.addClinicianform.get('txtProviderTitle').disable();
        this.addClinicianform.get('email').disable();
        this.addClinicianform.get('firstName').disable();
        this.addClinicianform.get('providerNPI').disable();
        this.addClinicianform.get('phone').disable();
        this.addClinicianform.get('lastName').reset();
      }
      else if(this.isErrorAlert === true)
      {
        this.ErrorMsg = Globalconstants.ErrorMsgClinicianAdded;
      }
    //}

      }

      

    }  
    catch(error)  
    { 
      var arrayParams = [sessionStorage.getItem('caserecid')]
      this.logger.log("SaveModalValues()","Error raised in caretool.component.ts - SaveModalValues() ", arrayParams,  error.message);                            
      this.isErrorAlert = true;
      this.ErrorMsg = Globalconstants.ErrorMessageUI; 
    } 
       
  }
  CancelModalValues(){
    this.addClinicianform.reset();
    this.modalRef.hide();
  }
  deleteObject(i) {    
    sessionStorage.setItem('IsChanged','Yes');
    this.newClinicians.splice(i,1);
  }

  deleteClinicalObject(i) {  

    try  
          { 
            sessionStorage.setItem('IsChanged','Yes');    
            this.isErrorAlert= false;
            this.ErrorMsg = ""; 
    let data = this.ClinicalIndicators.find(ob => ob['groupAttributeName'] === this.selectedIndicatorsIds2[i].id);                            
    if(data)
    {
      const index = this.arr.findIndex(x => x.attributeId === data.groupAttributeId);                                      
                  if (index !== -1) {
                    this.arr.splice(index, 1);
                  }

      for (let k = 0; k < data.groupSubAttribute.length; k++)
               {
                const indexChild = this.arr.findIndex(x => x.attributeId === data.groupSubAttribute[k].groupAttributeSubItemId);                                      
                  if (indexChild !== -1) {
                    this.arr.splice(indexChild, 1);
                  } 
                }                
      this.selectedIndicatorsIds2.splice(i,1);      
      this.getData();
    }
    
  }  
  catch(error)  
  { 
    var arrayParams = [sessionStorage.getItem('caserecid')]
    this.logger.log("deleteClinicalObject()","Error raised in caretool.component.ts - deleteClinicalObject() ", arrayParams,  error.message);                          
    this.isErrorAlert = true;
    this.ErrorMsg = Globalconstants.ErrorMessageUI; 
  } 
    
  }

  // deleteClinicalObjectPMH(i) { 
  //   try  
  //         { 
  //           sessionStorage.setItem('IsChanged','Yes');    
  //           this.isgridDisplayErrorPMH = false;
  //           this.ErrorMsgPastMedicalHistory = "";   
  //   let data = this.PastMedicalHistory.find(ob => ob['groupAttributeName'] === this.selectedPMHIds2[i].id);                            
  //   if(data)
  //   {
  //     const index = this.arrPMH.findIndex(x => x.attributeId === data.groupAttributeId);                                      
  //                 if (index !== -1) {
  //                   this.arrPMH.splice(index, 1);
  //                 }

  //     for (let k = 0; k < data.groupSubAttribute.length; k++)
  //              {
  //               const indexChild = this.arrPMH.findIndex(x => x.attributeId === data.groupSubAttribute[k].groupAttributeSubItemId);                                      
  //                 if (indexChild !== -1) {
  //                   this.arrPMH.splice(indexChild, 1);
  //                 } 
  //               }                
  //     this.selectedPMHIds2.splice(i,1);
  //     this.getDataPMH();
  //   }
  // }  
  // catch(error)  
  // { 
  //   var arrayParams = [sessionStorage.getItem('caserecid')]
  //   this.logger.log("deleteClinicalObjectPMH()","Error raised in caretool.component.ts - deleteClinicalObjectPMH() ", arrayParams,  error.message);                          
  //   this.isgridDisplayErrorPMH = true;
  //   this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMessageUI; 
  // }
    
  // }

  oncheckChange(searchValue: string): void { 
    
 
    if(searchValue)
    {
      this.hasErrorsManualLastname = false;      
      document.getElementById("LastnameLabel")?.classList?.remove('text-danger');
    }
    else{
      this.hasErrorsManualLastname = true;
      document.getElementById("LastnameLabel")?.classList?.add('text-danger');
    }
  }
  
  
  BindModalValuesToGrid(item: ProviderInfoResponse){
  
    //Get Provider Info from API
    if(Object.keys(item).length !=0)
      console.log(item);
    let Providerdata = this.newClinicians.find(ob => ob['providerNPI'] === item.providerNPI);                          
      if(Providerdata)
      {
        this.isErrorAlert = true;
        return true;
      }
        this.newClinicians.push(item);
        this.hasErrorsClinician= false;
        
  }

    ngOnInit(){
      // dynamic json 
      
          // this.providers = [];
          // //Get Provider Info from API
          // this.apiService.getProviderInfo().subscribe(
          //   (result: ProviderInfoResponse[]) =>{
          //       this.providers = result['providers'];                
          //   });

          // this.apiService.getFormValues().subscribe(
          //   result => {        
            var result =  JSON.parse(sessionStorage.getItem("formValuesObject"));
            if(result != undefined) {
                  this.ClinicalIndicators = result.form[0].Caretool.groups[0][Globalconstants.ClinicalIndicatorsKey].groupAttribute.sort((a,b) => a.groupAttributeName > b.groupAttributeName ? 1 : -1);                   
                  //this.PastMedicalHistory = result.form[0].Caretool.groups[0][Globalconstants.PastMedicalHistoryKey].groupAttribute; 
                  this.AnesTypeArrList = result.form[0].Caretool.groups[0][Globalconstants.AnesTypeArrListKey].groupAttribute; //Anes type
                  //this.PatinetTypeArrList = result.form[0].Caretool.groups[0][Globalconstants.PatinetTypeArrListKey].groupAttribute; //Patient type
                  //this.TimeElapsedArrList = result.form[0].Caretool.groups[0][Globalconstants.TimeElapsedArrListKey].groupAttribute.sort((a,b)=> a.groupAttributeOrder - b.groupAttributeOrder); //TimeElapsed
                  //this.ClinicalOutcmArrList = result.form[0].Caretool.groups[0][Globalconstants.ClinicalOutcmArrListKey].groupAttribute.sort((a,b)=> a.groupAttributeOrder - b.groupAttributeOrder); 
                  this.ASAClasificationArrList = result.form[0].Caretool.groups[0][Globalconstants.ASAClasificationArrListKey].groupAttribute.sort((a,b)=> a.groupAttributeOrder - b.groupAttributeOrder); 
                  //this.SourceofReportArrList = result.form[0].Caretool.groups[0][Globalconstants.SourceofReportArrListKey].groupAttribute;
                  //this.HeightUnitArrList = result.form[0].Caretool.groups[0][Globalconstants.HeightUnitArrListKey].groupAttribute;
                  //this.WeightUnitArrList = result.form[0].Caretool.groups[0][Globalconstants.WeightUnitArrListKey].groupAttribute;
                        
                  //Default selection source of report.
                  this.caretoolform.get('drpSOR').setValue('CT538');
                  this.caretoolform.get('CT000').setValue('0');   
                  this.parent.AdverseEventValue= enumAdverseEventValue.No;      
                  
                  //sessionStorage.removeItem('PastMedicalHistory'); 
                  sessionStorage.removeItem('AnesTypeArrList'); 
                  //sessionStorage.removeItem('PatinetTypeArrList'); 
                  //sessionStorage.removeItem('TimeElapsedArrList'); 
                  //sessionStorage.removeItem('ClinicalOutcmArrList'); 
                  sessionStorage.removeItem('ASAClasificationArrList'); 
                  //sessionStorage.removeItem('SourceofReportArrList'); 
                  //sessionStorage.removeItem('HeightUnitArrList'); 
                  //sessionStorage.removeItem('WeightUnitArrList'); 
                  sessionStorage.removeItem('ClinicalIndicators');
            
                  
                  //sessionStorage.setItem('PastMedicalHistory', JSON.stringify(this.PastMedicalHistory));
                  sessionStorage.setItem('AnesTypeArrList', JSON.stringify(this.AnesTypeArrList));
                  //sessionStorage.setItem('PatinetTypeArrList', JSON.stringify(this.PatinetTypeArrList));
                  //sessionStorage.setItem('TimeElapsedArrList', JSON.stringify(this.TimeElapsedArrList));
                  //sessionStorage.setItem('ClinicalOutcmArrList', JSON.stringify(this.ClinicalOutcmArrList));
                  sessionStorage.setItem('ASAClasificationArrList', JSON.stringify(this.ASAClasificationArrList));
                  //sessionStorage.setItem('SourceofReportArrList', JSON.stringify(this.SourceofReportArrList));
                  //sessionStorage.setItem('HeightUnitArrList', JSON.stringify(this.HeightUnitArrList));
                  //sessionStorage.setItem('WeightUnitArrList', JSON.stringify(this.WeightUnitArrList));
                  sessionStorage.setItem('ClinicalIndicators', JSON.stringify(this.ClinicalIndicators));
                  this.getData();
                  // this.getDataPMH(); 
            
                  //this.result = { "caseid": sessionStorage.getItem('caserecid'), "casetype":"Anesthesia", "providerNPI":"123456", "providerFirstName":"Mike", "providerLastName":"John", "phone":"1234456", "email":"mike.john@shcr.com",	 "patientDOS":"2020-01-01", "facilityName":"ADVANCEDSRGYCTROCONNOR", "facilityId":"2345", "gemnemonic":"",	"patientDemographics":{"mrn":"ANH83023",	"patientName":{"patientFirstName":"ANHANH","patientMiddleInitial":"j",	"patientLastName":"VUONG"},"patientDOB":"07-14-1957",	"gender":"Male" },	"anesthesiaType":"Other","asaLevel":"1","emergency":"1","postInductionCancellationFlag":"false"};
                // this.CaseRecID = +sessionStorage.getItem('caserecid');
                // if(this.CaseRecID > 0 && this.CaseRecID!= undefined)
                // { 
                //   this.apiService.getCaserecPatientDemographicDetails(this.CaseRecID.toString()).subscribe(
                //     result => { 
                                var resultCaseInfo =  JSON.parse(sessionStorage.getItem("caseInfoObject"));
                                if(resultCaseInfo != undefined)
                                {
                                //var iAnesType =  JSON.parse(sessionStorage.getItem('AnesTypeDocList'));  
                                let iAnesTypedata = this.AnesTypeArrList.find(ob => ob['groupAttributeName'] === resultCaseInfo.anesthesiaType); 
                                if(iAnesTypedata)
                                {    
                                  this.caretoolform.get('drpAnesthesiaType').setValue(iAnesTypedata.groupAttributeId);
                                  //this.caretoolform.get('drpAnesthesiaType').disable();
                                } 
            
                                let ConvertASAValue = "" ;
                                if(resultCaseInfo.asaLevel === ASALevelByenumKey.get(enumASALevel.P1)) {
                                  ConvertASAValue = enumASALevel.P1;
                                }
                                else if(resultCaseInfo.asaLevel === ASALevelByenumKey.get(enumASALevel.P2)) {
                                  ConvertASAValue = enumASALevel.P2;
                                }
                                else if(resultCaseInfo.asaLevel === ASALevelByenumKey.get(enumASALevel.P3)) {
                                  ConvertASAValue = enumASALevel.P3;
                                }
                                else if(resultCaseInfo.asaLevel === ASALevelByenumKey.get(enumASALevel.P4)) {
                                  ConvertASAValue = enumASALevel.P4;
                                }
                                else if(resultCaseInfo.asaLevel === ASALevelByenumKey.get(enumASALevel.P5)) {
                                  ConvertASAValue = enumASALevel.P5;
                                }
                                else  if(resultCaseInfo.asaLevel === ASALevelByenumKey.get(enumASALevel.P6)) {
                                  ConvertASAValue = enumASALevel.P6;
                                }
                                else{
                                  ConvertASAValue = resultCaseInfo.asaLevel;
                                }
                                //var iASA =  JSON.parse(sessionStorage.getItem('ASAStatus')); 
                                let iASAdata = this.ASAClasificationArrList.find(ob => ob['groupAttributeName'] === ConvertASAValue);                        
                                //console.log(this.ASAClasificationArrList);
                                //console.log(result.asaLevel);
                                if(iASAdata)
                                { 
                                  //console.log(iASAdata);   
                                  this.caretoolform.get('drpASA').setValue(iASAdata.groupAttributeId);
                                  //this.caretoolform.get('drpASA').disable();
                                }
                                
                                // if emergency values comes as empty or false then default to 0
                                if(resultCaseInfo.emergency == 'true' || resultCaseInfo.emergency == '1')
                                {
                                  //console.log(JSON.parse(this.result.emergency));
                                  this.caretoolform.get('CT533').setValue('1');
                                  //this.caretoolform.get('CT533').disable();
                                } else{
                                  this.caretoolform.get('CT533').setValue('0');
                                  //this.caretoolform.get('CT533').disable();
                                } 
                                  //get all caserec provider information
                                  
                                  this.objProvider = {
                                        providerFirstName:  resultCaseInfo.providerFirstName,
                                        providerLastName:   resultCaseInfo.providerLastName,
                                        providerMiddleName: '',
                                        providerId:         '',
                                        providerNPI:         resultCaseInfo.providerNPI,
                                        email:             resultCaseInfo.providerEmail,
                                        phone:              resultCaseInfo.providerPhone,
                                        serviceLine:        resultCaseInfo.caseType,
                                        providerRole    :   sessionStorage.getItem('role'),
                                        providerTitle  :'' ,
                                        isManualEntry :'0',
                                        caserecProvider:JSON.parse("true")
                                  }
                              }
                              this.BindDatafromDB(this.objProvider, resultCaseInfo.patientDOS); 
                  }
              //   }); 
              // }
                //end
                    
              // },
              //     error => {console.error(error); this.isErrorAlertAPICall = true; this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
              // );
        
          
         
             
              this.addClinicianform = this.fb.group({
                firstName: [null, [Validators.required, Validators.pattern(/.*\S.*/)]],
                lastName: [null, Validators.required],
                providerNPI: [null],        
                email: [null],
                phone: [null],
                ManualProvider:[null],
                hdnlastName:[null],
                txtProviderTitle:[null, [Validators.required, Validators.pattern(/.*\S.*/)]],
                ManualLastName:[null, Validators.pattern(/.*\S.*/)]
              })
              this.addClinicianform.get('firstName').disable();
              this.addClinicianform.get('txtProviderTitle').disable();      
              this.addClinicianform.get('email').enable();      
              this.addClinicianform.get('providerNPI').enable();
              this.addClinicianform.get('phone').enable();
          
    }
    
    //Clinical Indicators Child Checkbox seleciton method
    change(pAttributeName,data, children, event,i:number) {
      
try  
{     
  this.isIndigridDisplayError = false;
  this.ErrorMsgIndicators = ""; 
      
             if (event.target.checked === true) {
               this.ClinicalIndicators[i].isChecked = true;
               
               this.selectedIndicatorsIds.push({'name': pAttributeName,'id': data, checked: event.target.checked,'childname':'','CE':'CE' });                             
               const objTmp: attribute = {
                 
                 attributeId: data,
                 attributeValue: "1",
                 attributeAdditionalNotes:"",
                 attributeType: "Caretool"   
               };
               this.arr.push(objTmp);
               this.arrCancelData.push(objTmp);
              // console.log(this.arr);

              
               
               for (let child in children) {
                 this.selectedIndicatorsIds[this.selectedIndicatorsIds.length-1][child]=event.target.checked;
                 this.isIndigridDisplayError = true;
                 
               }
              // console.log(this.selectedIds);
             }
             if (event.target.checked === false) {
               this.ClinicalIndicators[i].isChecked = false;
               this.isIndigridDisplayError = false;
               this.selectedIndicatorsIds = this.selectedIndicatorsIds.filter((item) => item.id !== data);
               const index = this.arr.findIndex(x => x.attributeId === data);                      
                 if (index !== -1) {
                   this.arr.splice(index, 1);
                 } 
   
                  for (let child in children) {  
                    const index = this.arr.findIndex(x => x.attributeId === children[child].groupAttributeSubItemId);                      
                  if (index !== -1) {
                    this.arr.splice(index, 1);
                  } 
                }
   
                 
             }
            }  
            catch(error)  
            { 
              var arrayParams = [sessionStorage.getItem('caserecid')]
              this.logger.log("onChange()","Error raised in caretool.component.ts - ClinicalIndicators OnChange() ", arrayParams,  error.message);                           
              this.isIndigridDisplayError = true;
              this.ErrorMsgIndicators = Globalconstants.ErrorMessageUI; 
            } 

       }
       
   
         changeChild(ChildName, parentKey,childKey,event)
         {   
          try  
          {     
            this.isIndigridDisplayError = false;
            this.ErrorMsgIndicators = ""; 

             let item:any = this.selectedIndicatorsIds.find(x => x.id == parentKey)
             if (event.target.checked)
             {
            
              
            
               if(this.selectedIndicatorsIds.length>0)
               {
                  item[childKey]=event.target.checked;                    
                    if(item['childname'])
                    {
                        item['childname']  = item['childname'] + ','+ ChildName;
                    }
                    else{
                      item['childname']  = ChildName;
        
                    }
              }
   
               if(event.target.checked === true)
               {
               const objTmp: attribute = {              
                 attributeId: childKey,
                 attributeValue: "1",
                 attributeAdditionalNotes:"",
                 attributeType: "Caretool"   
               };
               this.arr.push(objTmp);
               this.arrCancelData.push(objTmp);
               this.isIndigridDisplayError = false;
              }              
            
             }
             else//
             {  
               //this.ClinicalIndicators[i].groupSubAttribute[childKey].isChecked = false;          
               const index = this.arr.findIndex(x => x.attributeId === childKey);
               //console.log(index);
               if(this.selectedIndicatorsIds.length>0)
               {
                    delete item[childKey]; 
                    // item[childKey]; 
                    item['childname'] = item['childname'].replace(ChildName,'');  
                    item['childname'] = item['childname'].replace(',,',','); 
                    item['childname'] = item['childname'].replace(',,',','); 
                    item['childname'] = item['childname'].replace(',,',',');  
                    if((item['childname']==",") || (item['childname']==""))       
                    {
                      this.isIndigridDisplayError= true;
                    }
                }
                 if (index !== -1) {
                   this.arr.splice(index, 1);
                 }        
                 //console.log(this.selectedIndicatorsIds);
            }
          }  
          catch(error)  
          { 
            var arrayParams = [sessionStorage.getItem('caserecid')]
            this.logger.log("changeChild()","Error raised in caretool.component.ts - changeChild ", arrayParams,  error.message);                           
            this.isIndigridDisplayError = true;
            this.ErrorMsgIndicators = Globalconstants.ErrorMessageUI; 
          } 
         }
         
         isChecked(parentKey,childKey) {
           let item:any = this.selectedIndicatorsIds.find(x => x.id == parentKey)
           return item ? item[childKey] : false
         }
   
      getData() {   
        try  
          {     
            this.isErrorAlertAPICall = false;
            this.ErrorMessageAPICall = "";         
            this.selectedIndicatorsIds1.length = 0;        
           var valClinicalindicators =  JSON.parse(sessionStorage.getItem('ClinicalIndicators'))
           //console.log('Call -123');
           this.ClinicalIndicators= valClinicalindicators;
           this.arrClinicalIndicators= []; 
           this.parentIndicators='';
          this.childIndicators='';
           
           if(this.arr)
           {
           for (let i = 0; i < this.arr.length; i++)
           {
                if(this.ClinicalIndicators){
                  for (let k = 0; k < this.ClinicalIndicators.length; k++)
                  {
                     if(this.arr[i].attributeId == this.ClinicalIndicators[k].groupAttributeId)
                     {
                        this.parentIndicators = this.ClinicalIndicators[k].groupAttributeName;
                        const objTmp: attribute = {              
                         attributeId: this.ClinicalIndicators[k].groupAttributeId,
                         attributeValue: "1" ,
                         attributeAdditionalNotes:"",
                         attributeType: "Caretool"   
                       };
                       if (!this.arrClinicalIndicators.some((item) => item.attributeId == this.ClinicalIndicators[k].groupAttributeId)) 
                       {
                        this.arrClinicalIndicators.push(objTmp);
                       }
                        this.ClinicalIndicators[k].isChecked = true;
                        break;
                     }
                     if(this.ClinicalIndicators[k].groupSubAttribute){
                       for(let j = 0; j < this.ClinicalIndicators[k].groupSubAttribute.length; j++)
                       {
                            if(this.arr[i].attributeId == this.ClinicalIndicators[k].groupSubAttribute[j].groupAttributeSubItemId)
                            {                      
                             const objTmpChild: attribute = {              
                               attributeId: this.ClinicalIndicators[k].groupSubAttribute[j].groupAttributeSubItemId,
                               attributeValue: "1" ,
                               attributeAdditionalNotes:"",
                               attributeType: "Caretool"   
                             };
                             
                             if (!this.arrClinicalIndicators.some((item) => item.attributeId == this.ClinicalIndicators[k].groupSubAttribute[j].groupAttributeSubItemId)) 
                             {
                               this.arrClinicalIndicators.push(objTmpChild);
                             }
                                 
                             
                             this.parentIndicators = this.ClinicalIndicators[k].groupAttributeName;
                              this.childIndicators = this.ClinicalIndicators[k].groupSubAttribute[j].groupAttributeSubItem;
                              this.ClinicalIndicators[k].groupSubAttribute[j].isChecked = true;
                                  var data = {'id':this.parentIndicators,'text': this.childIndicators};
                                  if(this.parentIndicators)
                                  {
                                    this.selectedIndicatorsIds1.push(data);
                                    this.parentIndicators="";
                                    this.childIndicators="";
                                  }
                                 // console.log(this.selectedIndicatorsIds1);
                            }
                         }
                     }
                }
               
                  
               }
               if (!this.selectedIndicatorsIds1.some((item) => item.id == this.parentIndicators)) 
               {
                 var data = {'id':this.parentIndicators,'text': ''};
                 if(this.parentIndicators)
                 {
                   this.selectedIndicatorsIds1.push(data);
                 }
             }
             //console.log(this.selectedIndicatorsIds1);
             
           }
           this.arr = this.arrClinicalIndicators;  
           
           var groups = this.selectedIndicatorsIds1.reduce(function(obj,item){
             obj[item.id] = obj[item.id] || [];
             if(item.text)
             {
                obj[item.id].push(item.text);
             }
             return obj;
         }, {});
         var myArray = Object.keys(groups).map(function(key){
             return {id: key, text: groups[key]};
         });
         //console.log(myArray);
         if(this.ChkMultiIndicators == false)
         {
          this.selectedIndicatorsIds2 = myArray
         }
          if(this.selectedIndicatorsIds2){
            if(this.selectedIndicatorsIds2.length > 0)
            {
                this.isIndigridDisplay = true;           
                this.hasErrorClinicalIndicators = false;           
            }
            else
            {
              this.isIndigridDisplay = false;
            }
          }
  
        }
        }  
        catch(error)  
        { 
          var arrayParams = [sessionStorage.getItem('caserecid')]
          this.logger.log("GetData()","Error raised in caretool.component.ts - Clinical Indicators Bind Data  ", arrayParams,  error.message);                         
          //this.isErrorAlertAPICall = true;
          //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
        }      
         
         
         }

         CheckMultiIndicatorValidation()      
         { 
           var valClinicalindicators =  JSON.parse(sessionStorage.getItem('ClinicalIndicators'));
           this.ClinicalIndicators= valClinicalindicators;
           if(this.arr)
           {  var IndicatorCount=0;                         
              for (let i = 0; i < this.arr.length; i++)
               {                 
                 let iAnesClinicalIndiPrimary = this.ClinicalIndicators.find(ob => ob['groupAttributeId'] === this.arr[i].attributeId); 
                 if(iAnesClinicalIndiPrimary)
                 { 
                    IndicatorCount =  IndicatorCount + 1;            
                 }  
                }

               if(IndicatorCount>1)
               {
                return true;       
               }
           }
           return false;   
         }


      ChildIndicatorValidation()      
      {
        var valClinicalindicators =  JSON.parse(sessionStorage.getItem('ClinicalIndicators'));
        this.ClinicalIndicators= valClinicalindicators;
        if(this.arr)
        {           
           for (let i = 0; i < this.arr.length; i++)
            { 
              
              let iAnesClinicalIndiPrimary = this.ClinicalIndicators.find(ob => ob['groupAttributeId'] === this.arr[i].attributeId); 
              if(iAnesClinicalIndiPrimary)
              { 
                var Count=0;
                if(iAnesClinicalIndiPrimary.groupSubAttribute.length > 0)
                {                 
                  for(let j=0;j<iAnesClinicalIndiPrimary.groupSubAttribute.length;j++)
                  {
                   let iAnesClinicalIndiSecondary = this.arr.find(ob => ob['attributeId'] === iAnesClinicalIndiPrimary.groupSubAttribute[j].groupAttributeSubItemId); 
                    
                    if(iAnesClinicalIndiSecondary)
                    {                     
                     Count = Count + 1;                     
                    }
                  }
                  
                  if(Count==0)
                  {
                    return true;
                  }

                }              
              }  
          }
            
        }
        return false;

      }
   
   
          getValidation()
          {
            this.ChkMultiIndicators = false;
            sessionStorage.setItem('IsChanged','Yes');
            //console.log(this.arr.length);
           if(this.arr.length<1)
           { this.isIndigridDisplayError = true;
             this.ErrorMsgIndicators = Globalconstants.ErrorMsgIndicators;            
           }   
           else if(this.isIndigridDisplayError == true)
           {        
             this.ErrorMsgIndicators = Globalconstants.ErrorMsgIndicatorsChild ;           
           }
          //  else if(this.selectedIndicatorsIds.length > 1)
          //  {        
          //   this.ErrorMsgIndicators = Globalconstants.ErrorMsgPrimaryIndicators; 
          //   this.isIndigridDisplayError = true; 
          //  }
          else
          {                          
              if (this.CheckMultiIndicatorValidation()==true)
              {
                this.ChkMultiIndicators = true;
                this.ErrorMsgIndicators = Globalconstants.ErrorMsgPrimaryIndicators; 
                //this.isIndigridDisplayError = true; 
                this.getData();       
              }
              else if (this.ChildIndicatorValidation()==true)
              {
                this.ErrorMsgIndicators = Globalconstants.ErrorMsgIndicatorsChild ; 
                this.isIndigridDisplayError = true; 
                this.getData();       
              } 
              else{
                    this.getData();
                    this.arrCancelData.length = 0;
                    this.modalRef.hide();  
              } 
          }  
        }
         
         getDataSave() : attribute[]
         {
              if(this.caretoolform.get('CT000').value ==="0")
              {
                return null;
              }
              else
              {          

                if(this.arrPMH.length > 0)
                {
                    return this.arrReturn = this.arr.concat(this.arrPMH);
                }
                else
                {          
                    return this.arr;
                }
              }
         }
   
  //      changePMH(pAttributeName,data, children, event,i:number) {
  //       try  
  //       {     
  //         this.isgridDisplayErrorPMH = false;
  //         this.ErrorMsgPastMedicalHistory = "";
      
  //       if (event.target.checked === true) {
  //         this.PastMedicalHistory[i].isChecked = true;
          
  //         this.selectedPMHIds.push({'name': pAttributeName,'id': data, checked: event.target.checked,'childname':'','CE':'CE' });              
  //         const objTmp: attribute = {
            
  //           attributeId: data,
  //           attributeValue: "1",
  //           attributeAdditionalNotes:"",
  //           attributeType: "Caretool"   
  //         };
  //         this.arrPMH.push(objTmp);
  //         this.arrCancelDataPMH.push(objTmp);
  //        // console.log(this.arr);n

         
          
  //         for (let child in children) {
  //           this.selectedPMHIds[this.selectedPMHIds.length-1][child]=event.target.checked;
  //           this.isgridDisplayErrorPMH = true;
  //           //console.log(this.selectedPMHIds);
            
  //         }
  //        // console.log(this.selectedIds);
  //       }
  //       if (event.target.checked === false) {
  //         this.PastMedicalHistory[i].isChecked = false;
  //         this.isgridDisplayErrorPMH = false;
  //         this.selectedPMHIds = this.selectedPMHIds.filter((item) => item.id !== data);
  //         const index = this.arrPMH.findIndex(x => x.attributeId === data);                      
  //           if (index !== -1) {
  //             this.arrPMH.splice(index, 1);
  //           } 

  //            for (let child in children) {  
  //              const index = this.arrPMH.findIndex(x => x.attributeId === children[child].groupAttributeSubItemId);                      
  //            if (index !== -1) {
  //              this.arrPMH.splice(index, 1);
  //            } 
  //          }

            
  //       }
  //     }  
  //     catch(error)  
  //     { 
  //       var arrayParams = [sessionStorage.getItem('caserecid')]
  //       this.logger.log("changePMH()","Error raised in caretool.component.ts - PMH", arrayParams,  error.message);                           
  //       this.isgridDisplayErrorPMH = true;
  //       this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMessageUI; 
  //     } 

  // }
  
    // //Past Medical  History Child Checkbox selection.
    // changeChildPMH(ChildName, parentKey,childKey,event)
    // {    
            
    //   try  
    //   {     
    //     this.isgridDisplayErrorPMH = false;
    //     this.ErrorMsgPastMedicalHistory = "";      

    //     let item:any = this.selectedPMHIds.find(x => x.id == parentKey)
        
    //     if (event.target.checked)
    //     {          
    //       if(this.selectedPMHIds.length>0)
    //       {
    //         item[childKey]=event.target.checked;
    //         if(item['childname'])
    //         {
    //             item['childname']  = item['childname'] + ','+ ChildName;
    //         }
    //         else{
    //           item['childname']  = ChildName;

    //         }
    //       }
                    

    //       if(event.target.checked === true)
    //       {
    //       const objTmp: attribute = {              
    //         attributeId: childKey,
    //         attributeValue: "1",
    //         attributeAdditionalNotes:"",
    //         attributeType: "Caretool"   
    //       };
    //       this.arrPMH.push(objTmp);
    //       this.arrCancelDataPMH.push(objTmp);
    //       this.isgridDisplayErrorPMH = false;
    //      }              
       
    //     }
    //     else//
    //     {  
    //       //this.ClinicalIndicators[i].groupSubAttribute[childKey].isChecked = false;          
    //       const index = this.arrPMH.findIndex(x => x.attributeId === childKey);
    //       //console.log(index);
    //       if(this.selectedPMHIds.length > 0)
    //       {          
    //           delete item[childKey];
    //           // item[childKey]; 
    //           item['childname'] = item['childname'].replace(ChildName,'');  
    //           item['childname'] = item['childname'].replace(',,',','); 
    //           item['childname'] = item['childname'].replace(',,',','); 
    //           item['childname'] = item['childname'].replace(',,',',');  
    //           if((item['childname']==",") || (item['childname']==""))       
    //           {
    //             this.isgridDisplayErrorPMH= true;
    //           }
    //       } 
         
    //         if (index !== -1) {
    //           this.arrPMH.splice(index, 1);
    //         }        
    //         //console.log(this.selectedIndicatorsIds);
    //    }
    //   }  
    //   catch(error)  
    //   { 
    //     var arrayParams = [sessionStorage.getItem('caserecid')]
    //     this.logger.log("changeChildPMH()","Error raised in caretool.component.ts - child PMH", arrayParams,  error.message);                            
    //     this.isgridDisplayErrorPMH = true;
    //     this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMessageUI; 
    //   } 


    // }
    isCheckedPMH(parentKey,childKey) {
      let item:any = this.selectedPMHIds.find(x => x.id == parentKey)
      return item ? item[childKey] : false
    }

//  getDataPMH() {  
   
// try  {     
//   this.isErrorAlertAPICall = false;
//   this.ErrorMessageAPICall = "";          
//     this.selectedPMHIds1.length = 0;        
//       var valClinicalindicators =  JSON.parse(sessionStorage.getItem('PastMedicalHistory'))
//       //console.log('Call -123');
//       this.PastMedicalHistory= valClinicalindicators;
//       this.arrPastMedicalHistory= []; 

//       this.parentIndicators='';
//       this.childIndicators='';
      
//       if(this.arrPMH)
//       {
//       for (let i = 0; i < this.arrPMH.length; i++)
//       {
//           if( this.PastMedicalHistory){
//             for (let k = 0; k < this.PastMedicalHistory.length; k++)
//             {
//                if(this.arrPMH[i].attributeId == this.PastMedicalHistory[k].groupAttributeId)
//                {
//                   this.parentIndicators = this.PastMedicalHistory[k].groupAttributeName;
//                   const objTmp: attribute = {              
//                    attributeId: this.PastMedicalHistory[k].groupAttributeId,
//                    attributeValue: "1" ,
//                    attributeAdditionalNotes:"",
//                    attributeType: "Caretool"   
//                  };
//                  if (!this.arrPastMedicalHistory.some((item) => item.attributeId == this.PastMedicalHistory[k].groupAttributeId)) 
//                  {
//                   this.arrPastMedicalHistory.push(objTmp);
//                  }
//                   this.PastMedicalHistory[k].isChecked = true;
//                   break;
//                }
//                if(this.PastMedicalHistory[k].groupSubAttribute){
//                 for(let j = 0; j < this.PastMedicalHistory[k].groupSubAttribute.length; j++)
//                 {
//                      if(this.arrPMH[i].attributeId == this.PastMedicalHistory[k].groupSubAttribute[j].groupAttributeSubItemId)
//                      {                      
//                       const objTmpChild: attribute = {              
//                         attributeId: this.PastMedicalHistory[k].groupSubAttribute[j].groupAttributeSubItemId,
//                         attributeValue: "1" ,
//                         attributeAdditionalNotes:"",
//                         attributeType: "Caretool"   
//                       };
                      
//                       if (!this.arrPastMedicalHistory.some((item) => item.attributeId == this.PastMedicalHistory[k].groupSubAttribute[j].groupAttributeSubItemId)) 
//                       {
//                         this.arrPastMedicalHistory.push(objTmpChild);
//                       }
                          
                      
//                       this.parentIndicators = this.PastMedicalHistory[k].groupAttributeName;
//                        this.childIndicators = this.PastMedicalHistory[k].groupSubAttribute[j].groupAttributeSubItem;
//                        this.PastMedicalHistory[k].groupSubAttribute[j].isChecked = true;
//                            var data = {'id':this.parentIndicators,'text': this.childIndicators};
//                            if(this.parentIndicators)
//                            {
//                              this.selectedPMHIds1.push(data);
//                              this.parentIndicators="";
//                              this.childIndicators="";
//                            }
//                           // console.log(this.selectedIndicatorsIds1);
//                      }
//                   }
//                }
               
//             }
//           }
          
//           if (!this.selectedPMHIds1.some((item) => item.id == this.parentIndicators)) 
//           {
//             var data = {'id':this.parentIndicators,'text': ''};
//             if(this.parentIndicators)
//             {
//               this.selectedPMHIds1.push(data);
//             }
//         }       
        
//       }
//       this.arrPMH = this.arrPastMedicalHistory;  
      
//       var groups = this.selectedPMHIds1.reduce(function(obj,item){
//         obj[item.id] = obj[item.id] || [];
//         if(item.text)
//         {
//            obj[item.id].push(item.text);
//         }
//         return obj;
//     }, {});
//     var myArray = Object.keys(groups).map(function(key){
//         return {id: key, text: groups[key]};
//     });
    
//     this.selectedPMHIds2 = myArray
//     if(this.selectedPMHIds2){
//         if(this.selectedPMHIds2.length > 0)
//         {
//             this.isgridDisplayPMH = true;           
//             this.hasErrorPastMedicalHistory = false;  
//             this.IsMIPSPMHNA  = false;        
//         }
//         else
//         {
//           this.isgridDisplayPMH = false;
//           this.IsMIPSPMHNA  = true;
//         }  
//     }
   
//   }     
    
//   }  
//   catch(error)  
//   { 
//     var arrayParams = [sessionStorage.getItem('caserecid')]
//     this.logger.log("getDataPMH()","Error raised in caretool.component.ts - Bind Data PMH", arrayParams,  error.message);                           
//    // this.isErrorAlertAPICall = true;
//     //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
//   } 
    
//     }

    // PMHValidation()      
    // {
    //   var valPMH=  JSON.parse(sessionStorage.getItem('PastMedicalHistory'));
    //   this.PastMedicalHistory= valPMH;
    //   if(this.arrPMH)
    //   {           
    //      for (let i = 0; i < this.arrPMH.length; i++)
    //       {              
    //         let iAnesPMHPrimary = this.PastMedicalHistory.find(ob => ob['groupAttributeId'] === this.arrPMH[i].attributeId); 
    //         if(iAnesPMHPrimary)
    //         { 
    //           var Count=0;
    //           if(iAnesPMHPrimary.groupSubAttribute.length > 0)
    //           {                 
    //             for(let j=0;j<iAnesPMHPrimary.groupSubAttribute.length;j++)
    //             {
    //              let iAnesPMHSecondary = this.arrPMH.find(ob => ob['attributeId'] === iAnesPMHPrimary.groupSubAttribute[j].groupAttributeSubItemId);                   
    //               if(iAnesPMHSecondary)
    //               {                     
    //                 Count = Count + 1;                     
    //               }
    //             }
                
    //             if(Count==0)
    //             {
    //               return true;
    //             }

    //           }              
    //         }  
    //     }
          
    //   }
    //   return false;

    // }

    // getValidationPMH()
    //       { 
    //         sessionStorage.setItem('IsChanged','Yes')
    //         if(this.arrPMH.length<1)
    //         { this.isgridDisplayErrorPMH = true;
    //           this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMsgPastMedicalHistory;            
    //         }   
    //         else if(this.isgridDisplayErrorPMH == true)
    //           {        
    //             this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMsgPastMedicalHistoryChild;           
    //           }
    //           else
    //           {

    //             if (this.PMHValidation()==true)
    //             {
    //               this.ErrorMsgPastMedicalHistory = Globalconstants.ErrorMsgPastMedicalHistoryChild;           
    //               this.isgridDisplayErrorPMH = true;
    //               //this.arrCancelDataPMH.length = 0; 
    //               this.getDataPMH();       
    //             }
    //             else{
    //                 this.getDataPMH();
    //                 this.arrCancelDataPMH.length = 0;
    //                 this.modalRef.hide();   
    //             }                 
                 
                
    //           }
   
    //       } 
      
   

      onFileChange(event) {
        try  
          { 
            sessionStorage.setItem('IsChanged','Yes');   
            this.isErrorAlertAPICall = false;
            this.ErrorMessageAPICall = ""; 

        if (event.target.files && event.target.files.length) {
                 
          this.selectedFiles = event.target.files;
          this.message ='';
          this.isFileSelected = true;
          this.messagecolor='';
          //validate file size
          if(this.fileValidator(this.selectedFiles)){
            this.messagecolor='text-danger';
            this.message = Globalconstants.FileSizemessage;
            return;
          }
          if(this.isValidFileExtension(this.selectedFiles)){
            this.messagecolor='text-danger';
             this.message = Globalconstants.ValidFilemessage;
             return;
          }
        } else{
          this.isFileSelected = true;
          this.selectedFiles = null;
        }

      }  
      catch(error)  
      { 
        var arrayParams = [sessionStorage.getItem('caserecid')]
        this.logger.log("onFileChange()","Error raised in caretool.component.ts - on upload porcess file change event", arrayParams,  error.message);                            
        //this.isErrorAlertAPICall = true;
        //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
      } 

      }

      
      uploadFiles() {
        try  
        { 
          sessionStorage.setItem('IsChanged','Yes');   
          this.isErrorAlertAPICall = false;
          this.ErrorMessageAPICall = ""; 
        if(this.selectedFiles !== null ){
          this.message = '';
          this.isDocgridDisplay = true;
          //this.isMultipleUploaded = false;
          if(this.uploadedFileInfo.length === 0){
              this.uploadedFileInfo =[];
          } else if(((this.uploadedFileInfo[0].fileName === '') && (this.uploadedFileInfo[0].filePath === '')) ){
            this.uploadedFileInfo =[];
          }
          if(this.fileValidator(this.selectedFiles)){
            this.messagecolor='text-danger';
            this.message = Globalconstants.FileSizemessage;
            return;
          }
            for (let i = 0; i < this.selectedFiles.length; i++) {
              let FileAlreadyuploaded = this.uploadedFileInfo.find(ob => ob['fileName'] === this.selectedFiles[i].name); 
              if(FileAlreadyuploaded)
              {
                this.messagecolor='text-danger';
                this.message = Globalconstants.FileAlreadyupload;
                return;
              }
                 this.uploadFile( this.selectedFiles[i], i);
                // this.SpinnerService.hide();  
            }
        } else{
          this.messagecolor='text-danger';
             this.message = Globalconstants.ValidFilemessage;
        }

      }  
      catch(error)  
      { 
        var arrayParams = [sessionStorage.getItem('caserecid')]
        this.logger.log("uploadFiles()","Error raised in caretool.component.ts - uploadFiles menthod", arrayParams,  error.message);                           
        //this.isErrorAlertAPICall = true;
        //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
      } 
       
        
  }

      uploadFile(file: File, fileNum: number) {
        const formData = new FormData();
        formData.append("file", file);
        //this.progress = 0;

       // this.currentFile = this.selectedFiles.item(0);
        this.apiService.uploadFiles(formData, sessionStorage.getItem('caserecid'), this.appName)
        .subscribe(
          result => {
          //   console.log(result);
          //  console.log("upload:",result[0].loaded);
          //  console.log("Event:",result[0].total);
            if (result[0].statusCode == "0" && result[0].status == Globalconstants.SuccessStatus)
            {
              setTimeout(() => {
                this.preventAbuse = false;
              }, 200);
                      
              const objFile: document = {
                fileName : file.name,
                filePath : result[0].documentURL
              }
              this.uploadedFileInfo.push(objFile);
              this.myInputVariable.nativeElement.value='';
              this.messagecolor='text-success';
              this.message =Globalconstants.FileUploadmessage;    
            }
            else{
             // this.progress = 0;
              this.messagecolor='text-danger';
              this.message = Globalconstants.FileNotUploadmessage;
             // this.currentFile = undefined;
             // alert('Record Not Saved Successfully!!');
            }     
          
          },
          error => {
                    this.logger.log("uploadFiles()","Error raised in caretool.component.ts during upload Files  ", [sessionStorage.getItem('caserecid')],  error.message); 
                    console.error(error); 
                    this.isErrorAlertAPICall = true;
                    this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},        
          );
          
      }

      GetFile(event){
        var fileName = event.target.innerText.split('/').pop();
        //document.getElementById("link")[0].getAttribute("href");
        //console.log(event);
        this.apiService.getFileByName(sessionStorage.getItem('caserecid'), this.appName, fileName)
          .subscribe(
            result => {
            // console.log(result.fileUrl);
             window.open(result.fileUrl);
             // this.downloadFile(result, fileName);
              //console.log("File size:",new Blob([JSON.stringify(result)]).size);

            },
            error => {
                      this.logger.log("getFileByName()","Error raised in caretool.component.ts in getfilebyName method   ", [sessionStorage.getItem('caserecid'),this.appName, fileName],  error.message); 
                      console.error(error);
                      this.isErrorAlertAPICall = true;
                      this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},        
          );

      }
  
      fileValidator(files: FileList) {
        let fileSizeInMB = 0;
        // for (let i = 0; i < files.length; i++) {
        //   fileSizeInMB = fileSizeInMB + files[i].size;
        // }

        for (let i = 0; i < files.length; i++) {
          fileSizeInMB = files[i].size;

          if(fileSizeInMB > 15728640){
            return true;
          } 
        }
        return false;
      }
      isValidFileExtension(files:FileList){
         let isInValidFileType = false;
         
            for (let i = 0; i < files.length; i++) {
              let extension = files[i].name.split('.').pop();
              //To Check valid file type extension
              // if (extension === "bat" || extension === "exe") {
              //     return true;
              // }
              this.inValidFileTypes.split(',').find(ext => {
                if (ext.trim() === extension) {
                  isInValidFileType =  true;
                }
              });
          }
          
          return isInValidFileType;
      }
      
      // onChangeBMI(event){
      //   sessionStorage.setItem('IsChanged','Yes');

      //   try  
      //   {     
      //     this.isErrorAlertAPICall = false;
      //     this.ErrorMessageAPICall = ""; 

      //   if (this.caretoolform.get('drpHeightUnit').value =="CT502") 
      //   {
      //     this.HGT =  this.caretoolform.get('CT548').value;
      //   }
      //   else if(this.caretoolform.get('drpHeightUnit').value =="CT503")
      //   {
      //     this.HGT = ((this.caretoolform.get('CT548').value) * 2.54);
      //   }
      //   else
      //   {this.HGT = null;}

      //   if (this.caretoolform.get('drpWeightUnit').value =="CT504") 
      //   {
      //     this.WGT =  this.caretoolform.get('CT549').value;
      //   }
      //   else if(this.caretoolform.get('drpWeightUnit').value =="CT505")        
      //   {
      //     this.WGT =  ((this.caretoolform.get('CT549').value) * 0.4535924);
      //   }
      //   else{
      //     this.WGT = null;
      //   }
        
      //   this.BMI = null;
      //   if(this.WGT> 0 && this.HGT> 0 )
      //   {
      //   this.BMI = parseFloat((this.WGT/((this.HGT/100) *(this.HGT/100))).toFixed(2));
      //   }
      //   else{
      //     //this.caretoolform.get('CT550').reset();
      //   }

              
        
      //   if(this.BMI>0)  
      //   {
      //     this.caretoolform.get('CT550').setValue(this.BMI.toString());
      //   }
              
      //   else{
      //    // this.caretoolform.get('CT550').reset();
      //   }
      // }  
      // catch(error)  
      // { 
      //   var arrayParams = [sessionStorage.getItem('caserecid')]
      //   this.logger.log("onChangeBMI()","Error raised in caretool.component.ts - onChangeBMI ", arrayParams,  error.message);                           
      //   //this.isErrorAlertAPICall = true;
      //   //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
      // } 

      // }

      CheckSameAsDOS(event)
      {
        //console.log(event.target.value);
        sessionStorage.setItem('IsChanged','Yes');
        if(event.target.checked){      
          this.caretoolform.get('CT605').setValue(this.SameAsDOS);
        } 
        else{
          this.caretoolform.get('CT605').setValue('');
        }

      }  

      SetChangeValues()
      {
        sessionStorage.setItem('IsChanged','Yes');
        //console.log(sessionStorage.getItem('IsChanged'));
      }
      
      downloadFile(response, fileName: string) {
              
      try  
      {     
        this.isErrorAlertAPICall = false;
        this.ErrorMessageAPICall = ""; 
       // console.log("1: ",response.headers);
        let extension = fileName.split('.')[1].toLowerCase();
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([response], { type: this.createFileType(extension) })
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
    
        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        window.open(data,"_blank")
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 400)
      }  
      catch(error)  
      { 
        var arrayParams = [response, fileName, sessionStorage.getItem('caserecid')];
        this.logger.log("downloadFile()","Error raised in caretool.component.ts - downloadFile ", arrayParams,  error.message);                          
       // this.isErrorAlertAPICall = true;
        //this.ErrorMessageAPICall = Globalconstants.ErrorMessageUI; 
      } 
      }
    
      createFileType(e): string {
        let fileType: string = "";
        if (e == 'pdf') {
          fileType = `application/${e}`;
        }
        else if(e == 'csv') {
          fileType = `application/${e}`;
        }
        else if (e == 'jpeg' || e == 'jpg' || e == 'png') {
          fileType = `image/${e}`;
        }
        else if (e == 'txt') {
          fileType = 'text/plain';
        }
        else if (e == 'ppt' || e == 'pot' || e == 'pps' || e == 'ppa') {
          fileType = 'application/vnd.ms-powerpoint';
        }
        else if (e == 'pptx') {
          fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        }
        else if (e == 'doc' || e == 'dot') {
          fileType = 'application/msword';
        }
        else if (e == 'docx') {
          fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        else if (e == 'xls' || e == 'xlt' || e == 'xla') {
          fileType = 'application/vnd.ms-excel';
        }
        else if (e == 'xlsx') {
          fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
    
        return fileType;
      }

      FileDelete(i){
        var x=confirm(Globalconstants.FileConfirmmessage);
       // alert(x);
        if(x) 
        {
          sessionStorage.setItem('IsChanged','Yes');
          let docInfo = this.uploadedFileInfo[i]   
          //delete file from AWS S3 bucket
          this.apiService.deleteFile(sessionStorage.getItem('caserecid'), this.appName, docInfo.fileName)
          .subscribe(
            result => {
             //console.log(result);
             //delete file record from grid.
             this.uploadedFileInfo.splice(i,1);
             this.messagecolor='text-success';
             this.message =Globalconstants.FileDeletemessage;
             this.myInputVariable.nativeElement.value='';
            },
            error => {
              this.logger.log("FileDelete()","Error raised in caretool.component.ts in fileDelete method", [sessionStorage.getItem('caserecid'), this.appName, docInfo.fileName],  error.message); 
                      console.error(error); 
              this.isErrorAlertAPICall = true; 
              this.ErrorMessageAPICall = Globalconstants.ErrorMessageAPICall;},
          );
        }
      }

      onSearchChange(searchValue: string): boolean {  
        if(searchValue)        {
          this.isEmailValidate = (this.validateEmail(searchValue));
        }
        else
        {
         return  this.isEmailValidate = true;
        }
      }

      onSearchDOS(searchValue: string): void { 
        sessionStorage.setItem('IsChanged','Yes'); 
        if(searchValue)        {
         
         var dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/;
         if(searchValue.match(dateReg))
         {
            document.getElementById("CT605").classList.remove('text-danger');
            this.isValidDOS = true;
         }
         else
         {
            document.getElementById("CT605").classList.add('text-danger');
            this.isValidDOS = false;
         }
        }
        else{
          this.isValidDOS = true;

        }
        
      }
      
      
      validateEmail(email):boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    onClickMsg(strStatus:string)
    {
        if(strStatus=="E")
        {
          this.isErrorAlertAPICall = false;
          this.ErrorMessageAPICall =  "";

        }

    }
    onStarted() {
      //document.getElementById("overlay").classList.add('disableddiv');
      this.startedClass = true;
      setTimeout(() => {
        this.startedClass = false;
      }, 50);
    }
  
    onCompleted() {
      //document.getElementById("overlay").classList.remove('disableddiv');
      this.completedClass = true;
      setTimeout(() => {
        this.completedClass = false;
      }, 50);
    }
    
    onChangeASA()
    {
      sessionStorage.setItem('IsChanged','Yes');
      if((!this.caretoolform.controls["drpASA"].value) && (this.RiskStatusError==true))     
      {
        this.RiskdrpASA =  true;
      }
      else{
        this.RiskdrpASA =  false;
      }

      if((!this.caretoolform.controls["drpTimeElapsed"].value) && (this.RiskStatusError==true)) 
      {
        this.RiskdrpTimeElapsed =  true;
      }
      else
      {
        this.RiskdrpTimeElapsed =  false;
      }

      if((!this.caretoolform.controls["drpClinicalOutcome"].value) && (this.RiskStatusError==true)) 
      {
        this.RiskdrpClinicalOutcome = true;
      }
      else{
        this.RiskdrpClinicalOutcome = false;
          }

      if((!this.caretoolform.controls["drpSOR"].value) && (this.RiskStatusError==true)) 
      {
        this.RiskdrpSOR = true;
      }
      else{
        this.RiskdrpSOR = false;
      }


      if((!this.caretoolform.controls["CT607"].value.trim()) && (this.RiskStatusError==true)) 
      {
        this.RiskCT607 = true;
      }
      else{
        this.RiskCT607 = false;
      }

      // if((this.selectedPMHIds2.length<1) && (this.RiskStatusError==true)) 
      // {
      //   this.hasErrorPastMedicalHistory=true;
      // }
      // else
      // {
      //   this.hasErrorPastMedicalHistory=false;
      // }


    }

   
    onChangeRiskManagement(isChecked: boolean){     
      if(isChecked === true){
        this.IsMIPSCOINA = false;
        this.RiskStatusError = true;
        if(!this.caretoolform.controls["drpASA"].value)        
        {
          this.RiskdrpASA =  true;
        }
        else{
          this.RiskdrpASA =  false;
        }

        if(!this.caretoolform.controls["drpTimeElapsed"].value)
        {
          this.RiskdrpTimeElapsed =  true;
        }
        else
        {
          this.RiskdrpTimeElapsed =  false;
        }

        if(!this.caretoolform.controls["drpClinicalOutcome"].value)
        {
          this.RiskdrpClinicalOutcome = true;
        }
        else{
          this.RiskdrpClinicalOutcome = false;
            }

        if(!this.caretoolform.controls["drpSOR"].value)
        {
          this.RiskdrpSOR = true;
        }
        else{
          this.RiskdrpSOR = false;
        }


        if(!this.caretoolform.controls["CT607"].value)
        {
          this.RiskCT607 = true;
        }
        else{
          this.RiskCT607 = false;
        }

        // if(this.selectedPMHIds2.length<1)
        // {
        //   this.hasErrorPastMedicalHistory=true;
        // }
        // else
        // {
        //   this.hasErrorPastMedicalHistory=false;
        // }

      }
      else{
        this.IsMIPSCOINA = true;
        this.RiskStatusError = false;
        this.RiskdrpASA = false;
        this.RiskdrpTimeElapsed= false;
        this.RiskdrpClinicalOutcome = false;
        this.RiskdrpSOR= false;
        this.RiskCT607= false;
        this.hasErrorPastMedicalHistory=false;
      }
    }

    // detect if browser is from mobile/tablet or from desktop
    isMobileDevice(){
      // credit to Timothy Huang for this regex test: 
      // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          return true
      }
      else{
          return false
      }
  }
  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    if (error.mediaStreamError && (error.mediaStreamError.name === "NotAllowedError" || error.mediaStreamError.name === "NotFoundError" )) {
      this.TurnOffCamera();
      this.cameraPermission = "You have cancelled your device's access to camera."
      
    }
    
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.camImage = true;
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
  public TurnOnCamera(): void{
    this.showWebcam = true;
    this.HideCamera = false;
    this.camImage = false;      
}
  
  public TurnOffCamera(): void{
    this.showWebcam = false;
    this.HideCamera = true;
    this.camImage = false;
  }
  public triggerSnapshot(): void {
    this.trigger.next();
   //this.isMobile = this.isMobileDevice();
   this.showWebcam = false;
   this.HideCamera = false;
   this.camImage = true;
   console.log("pic ",this.webcamImage);
  // console.log(this.webcamImage.imageData);
  }   
  public numericOnly(event):boolean { 
    var regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    var theValue  = this.caretoolform.get('CT550').value;
    if(theValue != null && theValue != "")
    {     
        theValue = theValue.toString();        
        if(String(theValue.match(regex)))
        {
          this.caretoolform.get('CT550').setValue(this.caretoolform.get('CT550').value.toString().replace(/[^0-9\.]*/g, ''))
          this.caretoolform.get('CT550').setValue(this.caretoolform.get('CT550').value.toString().replace(/[\.]/g, function(match, offset, all) { 
            return match === "." ? (all.indexOf(".") === offset ? '.' : '') : ''; 
         }))
        }
        var valIndex = theValue.indexOf(".")
        if(valIndex != -1)
        {            
          var input1 = theValue.substring(theValue.indexOf("."),theValue.length);
          if(input1.length>3)
          {
            this.caretoolform.get('CT550').setValue(theValue.substring(0,valIndex+3));
          }          
        }    
    }
    return true;
  } 
  
  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerWidth;
    this.height = win.innerHeight;
  }

  uploadImageFile(){
    this.getImage(this.webcamImage.imageAsDataUrl);      
  }
  uploadImageFile1(){
    this.mobileMessage =Globalconstants.FileUploadmessage;  
      this.mobileMessagecolor='text-success';
      this.isDocgridDisplay = true;
      //this.messagecolor='';
      this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
        const imageBlob: Blob = blob;
        const imageName: string = this.generateName();
        const imageFile: File = new File([imageBlob], imageName, {
          type: "image/jpeg"
        });
        this.uploadFile(imageFile, 1);
        console.log(imageName);
        console.log(imageFile);
        this.TurnOffCamera();
      });
  }

    /* Method to fetch image from Url */
    getBase64ImageFromURL(url: string): Observable<string> {
      return Observable.create((observer: Observer<string>) => {
        // create an image object
        let img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;
        if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
            observer.next(this.getBase64Image(img));
            observer.complete();
          };
          img.onerror = err => {
            observer.error(err);
          };
        } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
        }
      });
    }

    /* Method to create base64Data Url from fetched image */
    getBase64Image(img: HTMLImageElement): string {
      // We create a HTML canvas object that will create a 2d image
      var canvas: HTMLCanvasElement = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
      // This will draw image
      ctx.drawImage(img, 0, 0);
      // Convert the drawn image to Data URL
      let dataURL: string = canvas.toDataURL("image/png");
      this.base64DefaultURL = dataURL;
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

  /* Method called from the UI */
  getImage(imageUrl: string) {
    this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
      this.base64TrimmedURL = base64Data;
      //this.createBlobImageFileAndShow();
      this.uploadImageFile1();
    });
  }

   /**Method that will create Blob and show in new window */
    createBlobImageFileAndShow(): void {
      this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
        const imageBlob: Blob = blob;
        const imageName: string = this.generateName();
        const imageFile: File = new File([imageBlob], imageName, {
          type: "image/jpeg"
        });
        this.generatedImage = window.URL.createObjectURL(imageFile);
        window.open(this.generatedImage);
      });
    }

      /* Method to convert Base64Data Url as Image Blob */
      dataURItoBlob(dataURI: string): Observable<Blob> {
        return Observable.create((observer: Observer<Blob>) => {
          const byteString: string = window.atob(dataURI);
          const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
          const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([int8Array], { type: "image/jpeg" });
          observer.next(blob);
          observer.complete();
        });
      }

      /**Method to Generate a Name for the Image */
      generateName(): string {
        const date: string = formatDate( new Date(),  'MMddyyyyHHmmss', 'en_US');
        // let randomstring = '';
        // const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
        // for (let i = 0; i < 10; i++) {
        //     const rnum = Math.floor(Math.random() * chars.length);
        //     randomstring += chars.substring(rnum, rnum + 1);
        // }
        // Replace extension according to your media type like this
        return sessionStorage.getItem('caserecid') + "-" + date + ".jpeg";
      }

      omitSpecialChar(event) {
        var k;
        document.all ? k = event.keyCode : k = event.which;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32|| k == 39 || (k >= 48 && k <= 57));
    }
      
}

