<div class="container-fluid">
    <form [formGroup]="caretoolform" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-danger alert-dismissible" *ngIf="isErrorAlertAPICall">
                    <a href="javascript:void(0);" class="close" (click)="onClickMsg('E')" aria-label="close">&times;</a>
                    <strong>Failure! </strong> {{ErrorMessageAPICall}}
                </div>
            </div>
        </div>
        <p class="text-primary ml-3 pb-0">PATIENT SAFETY WORK PRODUCT</p>
        <div class="card">
            <div class="card-header font-weight-bold text-white" style="background-color:#5D87A1;">Clinical Anesthesia
                Report Of Events</div>
            <div class="card-body">
                <div class="form-row required align-items-center">
                    <div class="col-xs-3 col-sm-3 col-md-4 col-lg-3 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                        <label for="dvadverseEventYes" class="col-form-label"
                            [ngClass]="{'text-danger': hasAdverseEventErrors ===true}">
                            Perioperative Event
                        </label>
                    </div>
                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1 pb-2 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <input type="radio" formControlName="CT000" class="form-check-input pb-0"
                                id="dvadverseEventYes" name="CT000" value="1" (click)="toggleCARETOOLForm($event)">
                            <label for="dvadverseEventYes" class="form-check-label"
                                [ngClass]="{'text-danger': hasAdverseEventErrors===true}">
                                Yes
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-1 col-sm-2 col-md-2 col-lg-1  pb-0 pb-sm-0 pb-md-0 pb-lg-0">
                        <div class="form-check">
                            <input type="radio" formControlName="CT000" class="form-check-input" id="dvadverseEventNo"
                                name="CT000" value="0" (click)="toggleCARETOOLForm($event)">
                            <label for="dvadverseEventNo" class="form-check-label"
                                [ngClass]="{'text-danger': hasAdverseEventErrors===true}">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-7 col-sm-5 col-md-4 col-lg-7">

                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="isDisplay">
            <div class="card">
                <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Patient Safety Reporting
                    System(PSRS) Form</div>
                <div class="card-body">
                    <div class="form-row required pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3  pt-1">
                            <label for="CT605" class="col-form-label"
                                [ngClass]="{'text-danger': isFormSubmitted && caretoolform.controls['CT605'].errors?.required===true}">Date
                                Of Occurrence</label>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <input class="form-control" type="text" bsDatepicker #dp="bsDatepicker"
                                [bsConfig]="datePickerConfig" value="" autocomplete="off"
                                [ngClass]="{'border-danger': isFormSubmitted && caretoolform.controls['CT605'].errors?.required===true}"
                                formControlName="CT605" id="CT605" maxlength="10"
                                (input)="onSearchDOS($event.target.value)">
                        </div>
                        <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3 pt-3 pt-sm-0 pt-md-0 pt-lg-0">
                            <div class="form-check">
                                <input formControlName="SameAsDOS" class="form-check-input" type="checkbox" value="1"
                                    id="SameAsDOS" name="SameAsDOS" (change)="CheckSameAsDOS($event)">
                                <label class="form-check-label" for="SameAsDOS">
                                    Same as DOS
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-2 col-sm-1 col-md-2 col-lg-2"></div>
                    </div>
                    <div class="form-row required pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                            <label for="drpAnesthesiaType" class="col-form-label"
                                [ngClass]="{'text-danger': isFormSubmitted && caretoolform.controls['drpAnesthesiaType'].errors?.required===true}">Anesthesia
                                Type</label>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <select class="form-control"
                                [ngClass]="{'border-danger': isFormSubmitted && caretoolform.controls['drpAnesthesiaType'].errors?.required===true}"
                                formControlName="drpAnesthesiaType" id="drpAnesthesiaType"
                                (change)="SetChangeValues();">
                                <option value="">--Select--</option>
                                <option *ngFor="let val of this.AnesTypeArrList" value={{val.groupAttributeId}}>
                                    {{val.groupAttributeName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5"></div>
                    </div>
                    <div class="form-row required pb-2 align-items-center">
                        <div class="col-sm-3  col-md-3 col-lg-3  col-xs-3 ">
                            <label for="drpASA"  [ngClass]="{'text-danger': ((RiskdrpASA) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpASA'].errors?.required===true) || (isFormSubmitted && caretoolform.controls['drpASA'].errors?.required===true) )}" class="col-form-label">ASA Classification</label>
 
                        </div>
                        <div class="col-sm-4 col-md-3 col-lg-4 col-xs-4">
                            <select class="form-control"
                                [ngClass]="{'border-danger': ((RiskdrpASA) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpASA'].errors?.required===true) || (isFormSubmitted && caretoolform.controls['drpASA'].errors?.required===true))}"
                                (change)="onChangeASA()" formControlName="drpASA" id="drpASA">
                                <option value="">--Select--</option>
                                <option *ngFor="let val of this.ASAClasificationArrList" value={{val.groupAttributeId}}>
                                    {{val.groupAttributeName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-2 col-md-1 col-lg-2 col-xs-1  pb-1  pr-md-1">
                            <label class="col-form-label" for="CT533Yes">Emergency Status</label>
                        </div>
                        <div class="col-sm-2 col-md-2 col-lg-1 col-xs-1 pl-sm-3 pb-1  pl-sm-2 pl-md-5">
                            <div class="form-check ">
                                <input type="radio" formControlName="CT533" value="1" class="form-check-input"
                                    id="CT533Yes" name="CT533" (change)="SetChangeValues();">
                                <label class="form-check-label" for="CT533Yes">
                                    Yes
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-3 col-lg-1 col-xs-1 pb-1 pl-md-5 ">
                            <div class=" form-check">
                                <input type="radio" formControlName="CT533" class="form-check-input" value="0"
                                    id="CT533No" name="CT533" (change)="SetChangeValues();">
                                <label class="form-check-label" for="CT533No">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-0 col-md-0 col-lg-2 col-xs-2"></div>
                    </div>
                    <div class="form-row required align-items-center">
                        <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4">
                            <label class="card-title font-weight-bold col-form-label"
                                [ngClass]="{'text-danger': hasErrorClinicalIndicators ===true}">
                                Clinical Indicators
                            </label>
                        </div>
                        <div class="col-xs-8 col-sm-7 col-md-8 col-lg-8"></div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label [ngClass]="{'text-danger': isIndigridDisplay ===false}">
                                Please select the <b>one clinical indicator</b> that best describes the event which occurred.
                            </label>
                        </div>
                    </div>
                    <div class="form-row pb-2  pl-1">
                        <div class="col-xs-10 col-sm-12 col-md-10 col-lg-11">
                            <div class="form-row">
                                <div class="col-sm-4 border-right text-white" style="background-color:#80A1B6;">Clinical
                                    Indicator</div>
                                <div class="col-sm-6 border-right text-white" style="background-color:#80A1B6;">
                                    </div>
                                <div class="col-sm-2 text-white" style="background-color:#80A1B6;">Action</div>
                            </div>
                            <div *ngIf="isIndigridDisplay">
                                <div class="form-row border pt-0"
                                    *ngFor="let item of selectedIndicatorsIds2;let i = index">
                                    <div class="col-sm-4  border-right">{{ item.id }}</div>
                                    <div class="col-sm-6 border-right justify-content-center">
                                        {{ item.text}}
                                    </div>
                                    <div class="col-sm-2 justify-content-center align-items-center">
                                        <div *ngIf="isIncompleteCase">
                                        <Icon class="fa fa-trash pl-5" (click)="deleteClinicalObject(i)"
                                            style="color:red">
                                        </Icon>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2 col-sm-0 col-md-2 col-lg-2">

                        </div>
                    </div>
                    <div class="form-row">
                        <div
                            class="col-sm-4 offset-sm-8 col-md-3 offset-md-7 pl-md-3 pl-sm-0 pl-xs-0 pl-lg-0 col-lg-2 offset-lg-8">
                            <div *ngIf="isIncompleteCase">
                            <i class="fa fa-plus"></i>
                            <a href="javascript:void(0);" (click)="openClinicalIndicatorModal(template1)"
                                title="Add Clinical Indicators" class="text-body pl-1 ">Add Indicators</a>
                            </div>
                        </div>
                        <div class="col-sm-0 col-xs-2 col-md-2 col-lg-2">

                        </div>
                    </div>
                    <div class="form-row align-items-center pt-1 pt-sm-0">
                        <div class="col-xs-4 col-sm-6 col-md-4 col-lg-4 ml-0">
                            <label class="card-title font-weight-bold">
                                Clinician Information
                            </label>
                        </div>
                        <div class="col-xs-8 col-sm-6 col-md-8 col-lg-8">
                        </div>
                    </div>

                    <div class="form-row pb-2 pl-1">
                        <div class="col-xs-10 col-sm-12 col-md-10 col-lg-11">
                            <div class="form-row pb-0">
                                <div class="col-sm-4 border-right text-white" style="background-color:#80A1B6;">
                                    Clinician Name</div>
                                <div class="col-sm-2 border-right text-white" style="background-color:#80A1B6;">NPI
                                </div>
                                <div class="col-sm-4 border-right text-white" style="background-color:#80A1B6;">Email
                                </div>
                                <!-- <div class="col-sm-2 border-right text-white" style="background-color:#80A1B6;">Phone
                                </div> -->
                                <div class="col-sm-2 text-white" style="background-color:#80A1B6;">Action</div>
                            </div>
                            <div class="form-row row-striped  border pt-0"
                                *ngFor="let provider of newClinicians;let i = index">
                                <div class="col-sm-4 border-right">{{ provider.providerLastName + ' ' +
                                    provider.providerFirstName}}</div>
                                <div class="col-sm-2 border-right">{{ provider.providerNPI}}</div>
                                <div class="col-sm-4 border-right">{{ provider.email}}</div>
                                <!-- <div class="col-sm-2 border-right">{{ provider.phone}}</div> -->
                                <div class="col-sm-2 justify-content-center align-items-center">
                                    <div *ngIf="isIncompleteCase">
                                    <Icon *ngIf=" i !== 0 " class="fa fa-trash pl-5" (click)="deleteObject(i)"
                                        style="color:red">

                                    </Icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2 col-sm-0 col-md-2 col-lg-2">

                        </div>
                    </div>
                    <div class="form-row">
                        <div
                            class="col-xs-2 offset-xs-8 col-sm-4 offset-sm-8 pl-sm-2 pl-xs-0 pl-md-3 pl-lg-0 col-md-3 offset-md-7 col-lg-2 offset-lg-8">
                            <div *ngIf="isIncompleteCase">
                            <i class="fa fa-plus"></i>
                            <a href="javascript:void(0);" title="Add Additional Clinicians"
                                (click)="openModal(template)" class="text-body  pl-1">Add Clinicians</a>
                            </div>
                        </div>
                        <div class="col-xs-2 col-sm-0 col-md-2 col-lg-2">
                        </div>
                    </div>
                    <div class="form-row required pb-2 align-items-center">
                        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                            <label for="CT607" class="col-form-label"
                                [ngClass]="{'text-danger': ((RiskCT607) || (RiskStatusError && isFormSubmitted && caretoolform.controls['CT607'].errors?.required===true) || (isFormSubmitted && caretoolform.controls['CT607'].errors?.required===true))}">Brief Factual Summary</label>
                        </div>
                        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-8">
                            <textarea
                                [ngClass]="{'border-danger': ((RiskCT607) || (RiskStatusError && isFormSubmitted && caretoolform.controls['CT607'].errors?.required===true) || (isFormSubmitted && caretoolform.controls['CT607'].errors?.required===true))}"
                                class="form-control" maxlength="250" formControlName="CT607" id="CT607"
                                (change)="onChangeASA()" rows="3"></textarea>
                        </div>
                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
                    </div>
<!--
                    <div class="form-row  align-items-center">
                        <div class="col-xs-10 col-sm-12 col-md-10 col-lg-10">
                            <div class="card"  id="accordionAPI">
                                <div class="card-header font-weight-bold"  id="headerAPI" style="background-color:#D5DCE6" >
                                    <div class="form-row pb-2 align-items-center">
                                    <div  class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                    Additional Patient Information
                                    </div>

                                   <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                        <a [ngClass]="(IsMIPSAPINA=== false)?'btn btn-link':'btn btn-link collapsed'"  data-toggle="collapse" data-target="#collapseAPI" aria-expanded={{IsMIPSAPINA}} aria-controls="collapseAPI">
                                            <i class="fa fa-angle-double-down fa-2x" style="color:black" aria-hidden="true"> </i>
                                        </a>
                                    </div>
                                </div>
                                </div>

                            <div id="collapseAPI" [ngClass]="(IsMIPSAPINA=== false)?'collapse show':'collapse'" aria-labelledby="headerAPI" data-parent="#accordionAPI">
                                <div class="card-body">


                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                                <label for="drpPatientType" class="form-check-label">Patient Type</label>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                                <select class="form-control" [psSelectText]="'selectedText'" name="selectedProp"
                                    formControlName="drpPatientType" id="drpPatientType" (change)="SetChangeValues();">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.PatinetTypeArrList" value={{val.groupAttributeId}}>
                                        {{val.groupAttributeName}}
                                    </option>

                                </select>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                //one liner commented code <label for="CT550" class="form-check-label">BMI</label> 
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3  ">
                                //one liner commented code <input class="form-control" type="text"  formControlName="CT550" id="CT550" placeholder="Enter BMI">
                            </div>
                            <div class="col-xs-4 col-sm-0 col-md-4 col-lg-4">

                            </div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3  ">
                                <label for="CT548" class="form-check-label">Height</label>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                                <input class="form-control" type="text" formControlName="CT548" id="CT548"
                                    (change)="onChangeBMI($event)" placeholder="Unit of measure is inches or cms"
                                    numbersOnly>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <label for="drpHeightUnit" class="form-check-label">Unit</label>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3 ">
                                <select class="form-control " formControlName="drpHeightUnit" id="drpHeightUnit"
                                    (change)="onChangeBMI($event)">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.HeightUnitArrList" value={{val.groupAttributeId}}>
                                        {{val.groupAttributeName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-4 col-sm-0 col-md-4 col-lg-4">

                            </div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                                <label for="CT549" class="form-check-label">Weight</label>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                                <input class="form-control" type="text" formControlName="CT549" id="CT549"
                                    (change)="onChangeBMI($event)" placeholder="Unit of measure is kgs or lbs"
                                    numbersOnly />
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <label for="drpWeightUnit" class="form-check-label">Unit</label>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3  ">
                                <select class="form-control" formControlName="drpWeightUnit" id="drpWeightUnit"
                                    (change)="onChangeBMI($event)">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.WeightUnitArrList" value={{val.groupAttributeId}}>
                                        {{val.groupAttributeName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-4 col-sm-0 col-md-4 col-lg-4">

                            </div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                                <label for="CT550" class="form-check-label">BMI</label>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                                <input class="form-control" type="text"
                                formControlName="CT550" id="CT550" placeholder="Enter BMI"
                                (input)="numericOnly($event)" >
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                            </div>
                            <div class="col-xs-3 col-sm-4 col-md-3 col-lg-3">
                            </div>
                            <div class="col-xs-4 col-sm-0 col-md-4 col-lg-4">

                            </div>
                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-xs-2 col-sm-0 col-md-2 col-lg-2">

                        </div>
                    </div>
                    <div class="form-row  align-items-center">
                        <div class="col-xs-10 col-sm-12 col-md-10 col-lg-10">
                            <div class="card"  id="accordionCOI" >

                                <div class="card-header font-weight-bold"  id="headerCOI" style="background-color:#D5DCE6" >
                                    <div class="form-row pb-2 align-items-center">
                                    <div  class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                        Clinical Occurrence Information
                                    </div>

                                   <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                        <a [ngClass]="(IsMIPSCOINA=== false)?'btn btn-link':'btn btn-link collapsed'"  data-toggle="collapse" data-target="#collapseCOI" aria-expanded={{IsMIPSCOINA}} aria-controls="collapseCOI">
                                            <i class="fa fa-angle-double-down fa-2x" style="color:black" aria-hidden="true"> </i>
                                        </a>
                                    </div>
                                </div>
                                </div>

                            <div id="collapseCOI" [ngClass]="(IsMIPSCOINA=== false)?'collapse show':'collapse'" aria-labelledby="headerCOI" data-parent="#accordionCOI">
                                <div class="card-body">
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <label for="drpTimeElapsed"
                                    [ngClass]="{'text-danger': ((RiskdrpTimeElapsed) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpTimeElapsed'].errors?.required===true))}"
                                    class="form-check-label">Time Elapsed from DOS to Occurrence</label>
                            </div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                <select class="form-control"
                                    [ngClass]="{'border-danger': ((RiskdrpTimeElapsed) || ( RiskStatusError && isFormSubmitted && caretoolform.controls['drpTimeElapsed'].errors?.required===true))}"
                                    formControlName="drpTimeElapsed" (change)="onChangeASA()" id="drpTimeElapsed">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.TimeElapsedArrList" value={{val.groupAttributeId}}>
                                        {{val.groupAttributeName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <label for="drpClinicalOutcome"
                                    [ngClass]="{'text-danger': ((RiskdrpClinicalOutcome) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpClinicalOutcome'].errors?.required===true))}"
                                    class="form-check-label">Clinical Outcome</label>
                            </div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                <select class="form-control"
                                    [ngClass]="{'border-danger': ((RiskdrpClinicalOutcome) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpClinicalOutcome'].errors?.required===true))}"
                                    formControlName="drpClinicalOutcome" id="drpClinicalOutcome" (change)="onChangeASA()">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.ClinicalOutcmArrList" value={{val.groupAttributeId}}>
                                        {{val.groupAttributeName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 ">
                                <label for="drpSOR"
                                    [ngClass]="{'text-danger': ((RiskdrpSOR) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpSOR'].errors?.required===true))}">Source
                                    Of Report</label>
                            </div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                <select class="form-control"
                                    [ngClass]="{'border-danger': ((RiskdrpSOR) || (RiskStatusError && isFormSubmitted && caretoolform.controls['drpSOR'].errors?.required===true))}"
                                    formControlName="drpSOR" id="drpSOR" (change)="onChangeASA()">
                                    <option value="">--Select--</option>
                                    <option *ngFor="let val of this.SourceofReportArrList" value={{val.groupAttributeId}}>
                                        {{val.groupAttributeName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
                        </div>
                        <div class="form-row pb-2 align-items-center">
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3  ">
                                <label for="CT508" >Follow Up
                                    </label>
                            </div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                <textarea
                                    class="form-control" maxlength="8000" formControlName="CT508" id="CT508"
                                     rows="3"></textarea>
                            </div>
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-xs-2 col-sm-0 col-md-2 col-lg-2">

                        </div>
                    </div>
                    <div class="form-row  align-items-center">
                        <div class="col-xs-10 col-sm-12 col-md-10 col-lg-10">
                            <div class="card"  id="accordionPMH" >

                                <div class="card-header font-weight-bold"  id="headerPMH" style="background-color:#D5DCE6" >
                                    <div class="form-row pb-2 align-items-center">
                                    <div  class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                        <label [ngClass]="{'text-danger': ((hasErrorPastMedicalHistory) || (RiskStatusError && isFormSubmitted && hasErrorPastMedicalHistory ===true))}">
                                            Past Medical History
                                        </label>
                                    </div>

                                   <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                        <a [ngClass]="(IsMIPSPMHNA=== false)?'btn btn-link':'btn btn-link collapsed'"  data-toggle="collapse" data-target="#collapsePMH" aria-expanded={{IsMIPSPMHNA}} aria-controls="collapsePMH">
                                            <i class="fa fa-angle-double-down fa-2x" style="color:black" aria-hidden="true"> </i>
                                        </a>
                                    </div>
                                </div>
                                </div>

                            <div id="collapsePMH" [ngClass]="(IsMIPSPMHNA=== false)?'collapse show':'collapse'" aria-labelledby="headerPMH" data-parent="#accordionPMH">
                                <div class="card-body">

                        <div class="form-row pb-2 pl-1">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="form-row">
                                    <div class="col-sm-4 border-right text-white " style="background-color:#80A1B6;">Past
                                        Medical History</div>
                                    <div class="col-sm-6 border-right text-white" style="background-color:#80A1B6;">
                                        Secondary Past Medical History</div>
                                    <div class="col-sm-2 text-white" style="background-color:#80A1B6;">Action</div>
                                </div>
                                <div *ngIf="isgridDisplayPMH">
                                    <div class="form-row border pt-0" *ngFor="let item of selectedPMHIds2;let i = index">
                                        <div class="col-sm-4  border-right">{{ item.id }}</div>
                                        <div class="col-sm-6 border-right justify-content-center">
                                            {{ item.text}}
                                        </div>
                                        <div class="col-sm-2 justify-content-center align-items-center">
                                            <div *ngIf="isIncompleteCase">
                                            <Icon class="fa fa-trash pl-5" (click)="deleteClinicalObjectPMH(i)"
                                                style="color:red">
                                            </Icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-row">
                            <div
                                class=" col-xs-6 offset-xs-5 col-sm-6 offset-sm-5 col-md-6 offset-md-5 col-lg-3 offset-lg-8 pl-sm-11 pl-xs-0 pl-md-11 pl-lg-0">
                                <div *ngIf="isIncompleteCase">
                                <i class="fa fa-plus"></i>
                                <a href="javascript:void(0);" (click)="openPMHModal(templatePM)"
                                    title="Add Past Medical History" class="text-body pl-1 ">Add Medical History</a>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">

                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-xs-2 col-sm-0 col-md-2 col-lg-2">

                        </div>
                    </div>
                -->
                    <!-- </Start> -->


                    <br>
                    <!-- </End> -->

                    <!-- <div class="form-row pb-2 align-items-center">
                        <div class="col-sm-9 col-xs-3 col-lg-4 col-md-6 pl-4">
                            <label for="CT608" class="form-check-label">
                                <input type="checkbox" class="form-check-input" value="1" formControlName="CT608" id="CT608">
                                Submit To Risk Management
                            </label>
                        </div>
                        <div class="col-sm-3 col-xs-9 col-lg-8 col-md-6"></div>
                    </div> -->
                    <div *ngIf="!isMobile">
                        <ng-progress #progressBar (started)="onStarted()" (completed)="onCompleted()"></ng-progress>
                        <label [ngClass]="messagecolor" *ngIf="message">{{message}}</label>
                        <div class="form-row">
                            <div class="col-xs-9 col-sm-10 col-md-8 col-lg-10">
                                <input type="file" class="form-control" #inputFile formControlName="file" multiple
                                    (change)="onFileChange($event)" />
                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-1 col-lg-1 pt-2 pt-sm-0 pt-md-0 pt-lg-0">
                                <input type="button" class="btn btn-primary" value="Upload" [disabled]="!isFileSelected"
                                    (click)="uploadFiles()">

                            </div>
                            <div class="col-sm-1 col-xs-1 col-md-2 col-lg-2">
                            </div>
                        </div>
                        <div class="form-row pt-3" *ngIf="isDocgridDisplay">
                            <div class="col-sm-12 col-xs-10 col-md-10 col-lg-11">
                                <div class="form-row">
                                    <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 border-right text-white "
                                        style="background-color:#80A1B6;">File</div>

                                </div>
                                <div class="form-row border" *ngFor="let file of uploadedFileInfo;let i = index">
                                    <div class="col-sm-10 col-xs-10 col-md-10 col-lg-10 border-right">

                                            <div *ngIf="isIncompleteCase">
                                            <a
                                            (click)="GetFile($event)" href="javascript:void(0);" class="text-primary">{{
                                            file.fileName }}</a>
                                            </div>
                                            <div *ngIf="!isIncompleteCase">
                                                    <a>{{
                                                    file.fileName }}</a>
                                                </div>


                                        </div>
                                    <div
                                        class="col-sm-2 col-xs-2 col-md-2 col-lg-2 justify-content-center align-items-center">
                                        <div *ngIf="isIncompleteCase">
                                        <Icon class="fa fa-trash cursor pl-5" style="color:red;"
                                            (click)="FileDelete(i)">
                                        </Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-0 col-xs-2 col-md-2 col-lg-2">

                            </div>

                        </div>
                    </div>
                    <!-- <div class="form-row pb-2 align-items-center">
                        <div class="col-sm-9 col-xs-3 col-lg-4 col-md-6 pl-4">
                            <br>
                            <input type="checkbox" class="form-check-input" value="1" formControlName="CT608" id="CT608"
                                (ngModelChange)="onChangeRiskManagement($event)" (change)="SetChangeValues();">
                            <label for="CT608" class="form-check-label font-weight-bold">
                                Submit To Risk Management
                            </label>
                        </div>
                        <div class="col-sm-3 col-xs-9 col-lg-8 col-md-6"></div>
                    </div>
                    <div class="form-row pb-2 align-items-center">
                        <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12">
                            <label class="text-muted">
                                Clinical information will be required if you choose Submit to Risk Management
                            </label>
                        </div> -->
                        <!-- <div class="col-sm-3 col-xs-9 col-lg-8 col-md-6"></div> -->
                    <!-- </div> -->
                    <div *ngIf="isMobile">
                        <div class="card">
                            <div class="card-header font-weight-bold" style="background-color:#D5DCE6">Capture & Upload
                                Picture</div>
                            <div class="card-body">
                                <div *ngIf="HideCamera">
                                    <div class="form-row align-content-center justify-content-center pt-3">
                                        <div class="col-4 col-sm-4  col-md-4 col-xs-4 col-lg-4">

                                        </div>
                                        <div class="col-4 col-sm-4  col-md-4 col-xs-4 col-lg-4" style="font-size: 3rem">
                                            <i class="fa fa-camera fa-lg"></i>
                                        </div>
                                        <div class="col-4 col-sm-4  col-md-4 col-xs-4 col-lg-4">

                                        </div>
                                    </div>

                                    <div class="form-row justify-content-center">
                                        <div class="col-sm-12  col-md-12 col-xs-12 col-lg-12 pt-4">
                                            <label class="form-check-label">
                                                Take a photo of the patient details to upload
                                            </label>
                                                <label class="form-check-label" *ngIf="cameraPermission"
                                                style="color:red;">
                                                    {{cameraPermission}}
                                                </label>
                                        </div>
                                    </div>
                                    <div class="form-row justify-content-center">
                                        <div class="col-12 col-sm-12  col-md-12 col-xs-12 col-lg-12 pt-3">
                                                <div *ngIf="isIncompleteCase">
                                            <button type="button" class="btn btn-primary btn-block "
                                                (click)="TurnOnCamera();">Take a Photo

                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showWebcam">
                                    <div class="form-row justify-content-center" [height]="400" [width]="300">
                                        <webcam [height]="400" [width]="300" [trigger]="triggerObservable"
                                            (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                                            [allowCameraSwitch]="true" [switchCamera]="nextWebcamObservable"
                                            [imageQuality]="1" (initError)="handleInitError($event)"></webcam>

                                        <!-- Snapshot Div where image will be shown -->

                                    </div>
                                    <div class="form-row justify-content-center pt-2">
                                        <button type="button" class="btn btn-primary" (click)="triggerSnapshot();">Take
                                            Picture
                                        </button> &nbsp;
                                        <!-- <a class="btn btn-outline-primary" href="javascript:void(0);"  role="button" (click)="toggleWebcam();"> <img src="../../assets/images/switch-camera.png" height="23" width="30" alt="Toggle Camera"></a>
                                        -->
                                    </div>

                                </div>
                                <div *ngIf="camImage">
                                    <div class="form-row justify-content-center">
                                        <img [src]="webcamImage.imageAsDataUrl" [height]="400" [width]="300" />
                                    </div>
                                    <div class="form-row justify-content-center pt-3">
                                        <!-- Snapshot Div where image will be shown -->
                                        <button type="button" class="btn btn-primary"
                                            (click)="uploadImageFile();">Upload
                                        </button> &nbsp;
                                        <button class="btn btn-primary" (click)="TurnOnCamera();">Take Another
                                            Photo</button>

                                    </div>
                                </div>
                                <div class="form-row pt-3" *ngIf="isDocgridDisplay">
                                    <div class="col-sm-12 col-xs-10 col-md-10 col-lg-10">
                                        <div class="form-row">
                                            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 border-right text-white "
                                                style="background-color:#80A1B6;">File</div>
                                        </div>
                                        <div class="form-row border"
                                            *ngFor="let file of uploadedFileInfo;let i = index">
                                            <div *ngIf="isIncompleteCase">
                                            <div class="col-sm-10 col-xs-10 col-md-10 col-lg-10 border-right">
                                                <a
                                                    (click)="GetFile($event)" href="javascript:void(0);"
                                                    class="text-primary">{{ file.fileName }}</a>
                                            </div>
                                            </div>
                                            <div *ngIf="!isIncompleteCase">
                                               <a>{{ file.fileName }}</a>
                                            </div>
                                            <div
                                                class="col-sm-2 col-xs-2 col-md-2 col-lg-2 justify-content-center align-items-center">
                                                <div *ngIf="isIncompleteCase">
                                                <Icon class="fa fa-trash cursor pl-4" style="color:red;"
                                                    (click)="FileDelete(i)">
                                                </Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-0 col-xs-2 col-md-2 col-lg-2">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-primary pt-2">This is CONFIDENTIAL PATIENT SAFETY WORK PRODUCT and is protected from disclosure
            pursuant to applicable Federal statutes. Unauthorized disclosure or duplication is absolutely prohibited.
        </p>
    </form>
</div>

<ng-template #template>
    <div class="modal-header" style="background-color:#5D87A1;">
        <h4 class="modal-title pull-left text-white">Add Clinicians</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addClinicianform">

            <div class="form-group">
                <div class="col-sm-12">
                    <div *ngIf="isErrorAlert">
                        <strong class="text-danger">{{ErrorMsg}} </strong>
                    </div>
                </div>
            </div>
            <div class="form-group required">
                <label for="txtlastName" class="col-form-label" id="LastnameLabel"
                    [ngClass]="{'text-danger':isAddClinicianFormSubmitted && (!!addClinicianform.controls['lastName'].errors?.required===true || !!addClinicianform.controls['ManualLastName'].errors?.required===true) || !!addClinicianform.controls['ManualLastName'].errors?.pattern || hasErrorsManualLastname===true}">Clinician
                    Last Name</label>
                <div *ngIf="isLastNameDisplay">
                    <input class="form-control" formControlName="lastName" [(ngModel)]="model"
                        (selectItem)="selectedItem($event)" [ngbTypeahead]="search" type="text"
                        [resultFormatter]="formatter" [inputFormatter]="inputFormatter"
                        placeholder="Search by Clinician Last Name.." id="txtlastName"
                        [ngClass]="{'border-danger':isAddClinicianFormSubmitted && !!addClinicianform.controls['lastName'].errors?.required===true}">
                </div>
                <input type="hidden" formControlName="hdnlastName" name="hdnlastName" id="hdnlastName" value="">
                <div *ngIf="isManualLastNameDisplay">
                    <input class="form-control" formControlName="ManualLastName" maxlength="50" type="text" (keypress)="omitSpecialChar($event)"
                        id="txtManualLastName" (input)="oncheckChange($event.target.value)"
                        [ngClass]="{'border-danger':isAddClinicianFormSubmitted  && (hasErrorsManualLastname===true || !!addClinicianform.controls['ManualLastName'].errors?.required===true || !!addClinicianform.controls['ManualLastName'].errors?.pattern)}">
                </div>
            </div>
            <div class="form-group required">
                <label for="txtFirstName" class="col-form-label"
                    [ngClass]="{'text-danger':isAddClinicianFormSubmitted && (!!addClinicianform.controls['firstName'].errors?.required===true || !!addClinicianform.controls['firstName'].errors?.pattern)}">Clinician
                    First Name</label>
                <input class="form-control" formControlName="firstName" maxlength="50" type="text" id="txtFirstName" (keypress)="omitSpecialChar($event)"
                    [ngClass]="{'border-danger':isAddClinicianFormSubmitted && (!!addClinicianform.controls['firstName'].errors?.required===true || !!addClinicianform.controls['firstName'].errors?.pattern)}">
            </div>
            <div class="form-group required">
                <label for="txtProviderTitle" class="col-form-label"
                    [ngClass]="{'text-danger':isAddClinicianFormSubmitted && (!!addClinicianform.controls['txtProviderTitle'].errors?.required===true || !!addClinicianform.controls['txtProviderTitle'].errors?.pattern)}">Clinician
                    Title</label>
                <input class="form-control" type="text" maxlength="20" formControlName="txtProviderTitle" (keypress)="omitSpecialChar($event)"
                    id="txtProviderTitle"
                    [ngClass]="{'border-danger':isAddClinicianFormSubmitted && (!!addClinicianform.controls['txtProviderTitle'].errors?.required===true || !!addClinicianform.controls['txtProviderTitle'].errors?.pattern)}">
            </div>
            <div class="form-group">
                <label for="txtProviderNPI"
                    [ngClass]="{'text-danger':isAddClinicianFormSubmitted && !!addClinicianform.controls['providerNPI'].errors?.required===true}">Clinician
                    NPI</label>
                <input class="form-control" type="text" formControlName="providerNPI" maxlength="10" id="txtProviderNPI"
                    disabled phonenumbersOnly
                    [ngClass]="{'border-danger':isAddClinicianFormSubmitted && !!addClinicianform.controls['providerNPI'].errors?.required===true}">
            </div>
            <div class="form-group">
                <label for="txtEmail"
                    [ngClass]="{'text-danger':isAddClinicianFormSubmitted && !!addClinicianform.controls['email'].errors?.required===true}">Email</label>
                <input class="form-control" type="email" formControlName="email" id="txtEmail"
                    (input)="onSearchChange($event.target.value)" maxlength="100" disabled
                    [ngClass]="{'border-danger': isEmailValidate ===false}">

            </div>
            <div class="form-group">
                <label for="txtPhone" class="col-form-label align-content-end"
                    [ngClass]="{'text-danger':isAddClinicianFormSubmitted && !!addClinicianform.controls['phone'].errors?.required===true}">Phone</label>
                <input class="form-control" type="tel" formControlName="phone" id="txtPhone" maxlength="20" disabled
                    phonenumbersOnly
                    [ngClass]="{'border-danger':isAddClinicianFormSubmitted && !!addClinicianform.controls['phone'].errors?.required===true}">
            </div>
            <div class="form-group pl-4">
                <label for="chkManualProvider" class="form-check-label">
                    <input type="checkbox" class="form-check-input" formControlName="ManualProvider"
                        id="chkManualProvider" (change)="onManualProvider($event)">
                    External Clinician (Non Employee)
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger pr-1" (click)="CancelModalValues()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="SaveModalValues()"> Save</button>
    </div>
</ng-template>

<ng-template #template1>
    <div class="modal-header" style="background-color:#5D87A1;">
        <h4 class="modal-title pull-left  text-white">Clinical Indicators</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>
    <div class="form-row">
        <div class="col-sm-12 ml-2">
            <div *ngIf="isIndigridDisplayError">
                <label class="text-danger">{{ErrorMsgIndicators}} </label>
            </div>
            <div *ngIf="ChkMultiIndicators">
                <label class="text-danger">{{ErrorMsgIndicators}} </label>
            </div>
        </div>
    </div>

    <div class="modal-body question-modal-body">

        <!-- <form [formGroup]="addClinicalindicators"> -->
        <div class="container">
            <ul class="list-unstyled  row parentul" *ngFor="let parent of ClinicalIndicators;let i = index;">
                <li class="list-item col-sm-6  parentli">
                    <input type="checkbox" [(ngModel)]="parent.isChecked" #myItem
                        (change)="change(parent.groupAttributeName,parent.groupAttributeId,parent.groupSubAttribute,$event,i)">&nbsp;&nbsp;{{parent.groupAttributeName}}
                    <ng-container *ngIf="parent.isChecked && parent.hasOwnProperty('groupSubAttribute')">
                        <ul *ngFor="let child of parent.groupSubAttribute">
                            <li>
                                <input type="checkbox" [checked]="child.isChecked"
                                    (change)="changeChild(child.groupAttributeSubItem,parent.groupAttributeId,child.groupAttributeSubItemId,$event)" />
                                &nbsp;{{child.groupAttributeSubItem}}
                            </li>
                        </ul>
                    </ng-container>
                </li>
            </ul>
        </div>
        <!-- </form> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger pr-1" (click)="modalRef.hide()">Cancel</button>
        <input type="button" value="Save" (click)="getValidation()" class="btn btn-primary">
    </div>
</ng-template>


<ng-template #templatePM>
    <div class="modal-header" style="background-color:#5D87A1;">
        <h4 class="modal-title pull-left text-white">Past Medical History</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>

    <div class="modal-body question-modal-body">
        <div class="form-row">
            <div class="col-sm-12 ml-2">
                <div *ngIf="isgridDisplayErrorPMH">
                    <label class="text-danger">&nbsp;&nbsp;{{ErrorMsgPastMedicalHistory}} </label>
                </div>
            </div>
        </div>
        <!-- <form [formGroup]="addClinicalindicators"> -->
        <!-- <div class="container">
            <ul class="list-unstyled  row parentul" *ngFor="let parent of PastMedicalHistory;let i = index;">
                <li class="list-item col-sm-6  parentli">
                    <input type="checkbox" [(ngModel)]="parent.isChecked" #myItem
                        (change)="changePMH(parent.groupAttributeName,parent.groupAttributeId,parent.groupSubAttribute,$event,i)">&nbsp;&nbsp;{{parent.groupAttributeName}}
                    <ng-container *ngIf="parent.isChecked && parent.hasOwnProperty('groupSubAttribute')">
                        <ul *ngFor="let child of parent.groupSubAttribute">
                            <li>
                                <input type="checkbox" [checked]="child.isChecked"
                                    (change)="changeChildPMH(child.groupAttributeSubItem,parent.groupAttributeId,child.groupAttributeSubItemId,$event)" />
                                &nbsp;{{child.groupAttributeSubItem}}
                            </li>
                        </ul>
                    </ng-container>
                </li>
            </ul>
        </div> -->
        <!-- </form> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger pr-1" (click)="modalRef.hide()">Cancel</button>
        <input type="button" value="Save" (click)="getValidationPMH()" class="btn btn-primary">
    </div>
</ng-template>