export const environment = {
    production: false,
    caseDetailsByIDInfoAPIUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/caseinfo/',
    caseDetailsByIDAPIUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/cases/',
    saveCaseDetailsAPIUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/cases/',
    providerInfoAPIUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/providers',
    formValuesAPIUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/cases/formvalues',
    clientIDValue:'',
    clientSecretValue:'',
    caserecPatientDemographicAPIUrl:'https://stage-integrations.evhc.net/api/v1/caretool/caserec/cases/',
    fileuploadUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/cases/',
    //logServiceUrl: 'https://stage-integrations.evhc.net/api/v1/caretool/log?logType=Logstash',
    logServiceUrl: 'https://p49qo5vem4.execute-api.us-east-1.amazonaws.com/stage/weblog',
    envName : "stage",
    informationLogging: false,
    logIndexName: "quality-cqf-logs-stage",
    cutoffdate: "05/01/2024"

};