<!-- <footer class="footer text-align-center">
    <div class="container nvsn-pp-sub-footer">
        <div class="row">
            
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 app-copyright text-center">
                    <small>© Copyright 2020 Envision Healthcare</small>
            </div>
        </div>
    </div>
</footer> -->
<footer class="footer mb-0" style="background-color:#00274D;">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-2 " style="color:white;">
        © Copyright {{ currentYear  }} Envision Healthcare 
    </div>
    <!-- Copyright -->
  </footer>
