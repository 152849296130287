import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {  ErrorComponent } from './error/error.component';
import {  AuthenticationComponent } from './authentication/authentication.component';
import { AuthGuardService as AuthGuard } from './_services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '',   component: AuthenticationComponent , pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate:[AuthGuard] },
 // { path: 'home', component: HomeComponent },
  { path: 'error', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[]
})
export class AppRoutingModule { }
