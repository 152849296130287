<div class="container">
    <form [formGroup]="detailform"  (ngSubmit)="onSubmit()">
    <div class="form-row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header font-weight-bold text-white" style="background-color:#5D87A1;">
                        Case Information
                    </div>
                    <div class="card-body" >
                        <div class="form-row required align-items-center">
                            <div class="col-sm-2 col-xs-2 col-md-2 col-lg-2 ">
                                <label for="txtCaseRecID" class="form-check-label align-content-end" [ngClass]="{'text-danger': hasErrors===true}" >CaseRecID</label>
                            </div>
                            <div class="col-sm-4 col-xs-4 col-md-4 col-lg-4">
                                <input class="form-control" formControlName="txtCaseRecID" type="text" id="txtCaseRecID" [ngClass]="{'border-danger': hasErrors===true}">
                            </div>
                            <div class="col-sm-2 col-xs-2 col-md-2 col-lg-2">
                                <!-- <input tyep="button" class="btn btn-primary" value="ADD" (click)="search()"> -->
                            </div> 
                            <div class=" col-sm-4 col-xs-4 col-md-4 col-lg-4"></div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
<div class="form-row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header font-weight-bold" style="background-color:#D5DCE6;">
                Facility Information
            </div>
            <div class="card-body" >
                <div class="form-row align-items-center">
                    <div class="col-sm-2">
                        <label for="txtServiceLine" class="col-form-label align-content-end">Service Line</label>
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control" formControlName="Serviceline" type="text" value="" id="txtServiceLine" >
                    </div>
                    <div class="col-sm-2 d-flex  pl-sm-4 pl-1">
                        <label for="txtFacility" class="col-form-label">Facility Name</label>
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control" formControlName="Facility" type="text" value="" id="txtFacility" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="form-row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header font-weight-bold" style="background-color:#D5DCE6;">
                Reporting Clinician Information
            </div>
            <div class="card-body" >
                <div class="form-row pb-2 align-items-center">
                    <div class="col-sm-2">
                        <label for="txtClinician" class="col-form-label align-content-end">Clinician Last Name</label>
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control" formControlName="ClinicianLastName" type="text" value="" id="txtClinicianLastName" >
                    </div>
                    <div class="col-sm-2 d-flex  pl-sm-4 pl-1">
                        <label for="txtClinician" class="col-form-label">Clinician First Name</label>
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control" formControlName="ClinicianFirstName" type="text" value="" id="txtClinicianFirstName" >
                    </div>
                </div>
                <div class="form-row align-items-center">
                    <div class="col-sm-2">
                        <label for="txtEmail" class="col-form-label align-content-end">Email</label>
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control" formControlName="Email" type="email" value="" id="txtEmail" >
                    </div>
                    <div class="col-sm-2 d-flex  pl-sm-4 pl-1">
                        <label for="txtPhone" class="col-form-label">Phone</label>
                    </div>
                    <div class="col-sm-4">
                        <input class="form-control" formControlName="Phone" type="tel" value="" id="txtPhone" >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="form-row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header font-weight-bold" style="background-color:#D5DCE6;">
                Patient Demographics
            </div>
            <div class="card-body" >
            <div class="form-row pb-2 align-items-center">
                <div class="col-sm-6">
                        <label class="text-muted">
                            Patient demographics will be updated within 48 hours
                        </label>
                </div>
                <div class="col-sm-6"></div> 
            </div>
            <div class="form-row pb-2 align-items-center">
                <div class="col-sm-2">
                    <label for="txtFirstName" class="col-form-label align-content-end">First Name</label>
                </div>
                <div class="col-sm-4">
                    <input class="form-control" formControlName="FirstName"  type="text" value="" id="txtFirstName" >
                </div>
                <div class="col-sm-2 pl-sm-4 pl-1">
                    <label for="txtLastName" class="col-form-label">Last Name</label>
                </div>
                 <div class="col-sm-4">
                    <input class="form-control" type="text" formControlName="LastName" value="" id="txtLastName" >
                </div>
            </div>
            <div class="form-row pb-2 align-items-center">
                <div class="col-sm-2 ">
                    <label for="txtMiddleName" class="col-form-label align-content-end">Middle Name</label>
                </div>
                <div class="col-sm-4 ">
                    <input class="form-control" type="text" formControlName="MiddleName" value="" id="txtMiddleName" >
                </div>
                <div class="col-sm-2 pl-sm-4 pl-1">
                    <label for="txtGender" class="col-form-label align-content-end">Gender</label>
                </div>
                <div class="col-sm-4 ">
                    <input class="form-control" type="text" formControlName="Gender" value="" id="txtGender" >
                </div>
            </div>
            <div class="form-row pb-2 align-items-center">
                <div class="col-sm-2">
                    <label for="txtDOB" class="col-form-label">Date Of Birth</label>
                </div>
                 <div class="col-sm-4">
                    <input class="form-control" formControlName="DOB" type="text" value="" id="txtDOB" >
                </div>
                <div class="col-sm-2 pl-sm-4 pl-1">
                    <label for="txtMRN" class="col-form-label align-content-end">MRN</label>
                </div>
                <div class="col-sm-4">
                    <input class="form-control" formControlName="MRN" type="text" value="" id="txtMRN" >
                </div>
            </div>
            <div class="form-row align-items-center">
                <div class="col-sm-2">
                    <label for="txtDOS" class="col-form-label ">Date Of Service</label>
                </div>
                 <div class="col-sm-4">
                    <input class="form-control" formControlName="DOS" type="text" value="" id="txtDOS" >
                </div>
                <div class="col-sm-2 pl-sm-4 pl-1">
                    <!-- <label for="txtRace" class="col-form-label align-content-end">Race</label> -->
                </div>
                <div class="col-sm-4">
                    <!-- <input class="form-control" formControlName="Race"type="text" value="" id="txtRace" > -->
                    <input type="hidden" formControlName="hdnfacilityid" name="hdnfacilityid" id="hdnfacilityid" value ="" >   
                    <input type="hidden" formControlName="hdnpostInductionCancellationFlag" name="hdnpostInductionCancellationFlag" id="hdnpostInductionCancellationFlag" value ="" >                     
                    <input type="hidden" formControlName="hdngeMnemonic" name="hdngeMnemonic" id="hdngeMnemonic" value="">
                    <input type="hidden" formControlName="hdnhandofcase" name="hdnhandofcase" id="hdnhandofcase" value ="" >       
                </div>
            </div>
           
        </div>
      </div>
    </div>
</div>
    </form>
</div>
