import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, finalize } from 'rxjs/operators'; 
import { Router, ActivatedRoute } from '@angular/router';
import { LogService } from 'src/app/_services/log/log.service';


@Injectable()
export class JwttokenInterceptor implements HttpInterceptor {

  constructor(private logger: LogService, private route: Router) {

    
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = sessionStorage.getItem('accesstoken');
    
    if(token){
      const clonedReq = request.clone({
        headers:request.headers.set('Authorization',
        'Bearer ' + token)
      });
      //console.log('clonedReq----->',clonedReq);
      return next.handle(clonedReq)
      .pipe(
        //retry on failure
        retry(1),
        //Handle  errors
        catchError((error: HttpErrorResponse) =>{
          //Errorhandling logic here
         // alert(`Http Error: ${clonedReq.url}`);
         //sessionStorage.setItem('error', 'YES');
         //console.log("error from Interceptor:", error)
         if (error instanceof HttpErrorResponse && error.status === 401) {
            // redirect to the login route
            // or show a modal
            var arrayParams = [sessionStorage.getItem('caserecid'), error.status.toString()]
            this.logger.log("intercept()","Error raised in jwttoken.interceptor.ts - intercept() ", arrayParams,  error.message); 
            this.route.navigate(['/error'], { queryParams: { page: 'sessiontimeout' } });
            return throwError(error);
        } else{
          var arrayParams = [sessionStorage.getItem('caserecid'), error.status.toString()]
          this.logger.log("intercept()","Error raised in jwttoken.interceptor.ts - intercept() ", arrayParams,  error.message);
          return throwError(error);
        }
      }),
        //Profiling
        finalize(() => {
            const profilingMsg =`${request.method} "${request.urlWithParams}"`;
            //console.log(profilingMsg);
        })     
      );
    } else {
      return next.handle(request)
      .pipe(
        //retry on failure
        retry(2),
        //Handle  errors
        catchError((error: HttpErrorResponse) =>{
          //Errorhandling logic here
         // alert(`Http Error: ${clonedReq.url}`);
          //console.log("error from Interceptor:", error)//
          if (error instanceof HttpErrorResponse && error.status === 401) {
              // redirect to the login route
              // or show a modal
              var arrayParams = [sessionStorage.getItem('caserecid'), error.status.toString()]
              this.logger.log("intercept()","Error raised in jwttoken.interceptor.ts - intercept() ", arrayParams,  error.message);   
              this.route.navigate(['/error'], { queryParams: { page: 'sessiontimeout' } });
              return throwError(error);
          } else{
            var arrayParams = [sessionStorage.getItem('caserecid'), error.status.toString()]
            this.logger.log("intercept()","Error raised in jwttoken.interceptor.ts - intercept() ", arrayParams,  error.message);
            return throwError(error);
          }
      }),
        //Profiling
        finalize(() => {
            const profilingMsg =`${request.method} "${request.urlWithParams}"`;
            //console.log(profilingMsg);
        })     
      );
    }
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      // this.router.navigateByUrl('/error');
  }
}
