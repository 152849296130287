import { Injectable, OnInit } from '@angular/core';
import { Logdetails } from 'src/app/_models/logdetails';
import { MulesoftAPIServiceService } from 'src/app/_services/mulesoft/mulesoft-apiservice.service'; 
import{ Globalconstants } from 'src/app/helper/globalconstants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService implements OnInit {
  public logDtl : Logdetails;
  constructor(private apiService: MulesoftAPIServiceService) { }
  log(strMethodName: string, strMethodDescription: string, arrayParams: string[], errorMessage: string) {

    var arrayParam= [];
    this.logDtl = {
        "platform" : "",
        "appName" : "CentralizedQualityForms",
        "environment" : environment.envName,
        "Timestamp" : new Date(),
        "correlationId": "",
        "methodName" : strMethodName,
        "methodDescription" : strMethodDescription,
        "params" : arrayParams,
        "url" : "",
        "indexName": environment.logIndexName,
        "message": errorMessage,
        "messageStatus" :"",
        "clientApplicationName" : null
    }
      console.log(new Date() + ": " + JSON.stringify(this.logDtl));
      try{
      this.apiService.SaveLogDetails(this.logDtl).subscribe(
        result => {
            if (result != undefined )
            {
            }
         
          },
      );
        }
        catch(error){
          console.log(error)
        }
        
  }
  ngOnInit(): void {

  }

}
